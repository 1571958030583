const dashboardReducerDefaultState = {
    activeExams:[],
    upComingExams:{
        data:[],
        date:null
    },
    latestNews:[],
    showingIntroduction:false,
    examIdForIntroduction: null,
    examCodeForIntroduction:null

};

export default (state = dashboardReducerDefaultState, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_DATA':
            // console.log(action);
            return {
                ...state,
                activeExams: action.payload.active_exams,
                upComingExams:{
                    data:action.payload.upcoming_exams,
                    date:null
                },
            }
        case 'SHOW_EXAM_INTRODUCTION':
            const{showingIntroduction,examIdForIntroduction,examCodeForIntroduction }=action.payload
            return {
                ...state,
                showingIntroduction,
                examIdForIntroduction,
                examCodeForIntroduction
            }
        default:
            return state;
    }
};
