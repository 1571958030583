import React from 'react';
class ExamResultSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }
  render() {
    const {
      questionsByCourses,
      evaluationType,
      trueQuestions,
      falseQuestions,
      emptyQuestions,
    } = this.state;

    let _questionPoint= this.state.questionPoint;
    let _pointFromAllCourses= this.state.pointFromAllCourses;
    let _netFromAllCourses= this.state.netFromAllCourses;


    return (
      <div className="exam-over-summary">
        <h5 className="d-block">Your Exam Summary:</h5>
        <br />
        <table className="table">
          <thead>
            <tr>
              <th>Courses:</th>
              <th>Correct:</th>
              <th>Incorrect</th>
              <th>Empty:</th>
              <th>Net:</th>
              <th>Score:</th>
            </tr>
          </thead>

          <tbody>
            {Object.entries(questionsByCourses).map(([key, cItem]) => {
              console.log(cItem);
              let netTrue =
                cItem.falseQuestions > 0
                  ? cItem.trueQuestions -
                    (evaluationType > 0
                      ? cItem.falseQuestions / parseInt(evaluationType)
                      : 0
                    ).toFixed(2)
                  : cItem.trueQuestions;

              netTrue = netTrue < 0 ? 0 : netTrue; //check if net is small than 0
              console.log(
                'evaluationType > 0',
                evaluationType > 0,
                cItem.default_question_point,
                cItem._questionPoint,
                netTrue
              );
              _questionPoint =
                evaluationType > 0
                  ? cItem.default_question_point * netTrue
                  : cItem._questionPoint * cItem.trueQuestions;
              _pointFromAllCourses += _questionPoint;
              _netFromAllCourses += netTrue;

              return (
                <tr key={key}>
                  <th>{cItem.short_name}</th>
                  <td>{cItem.trueQuestions}</td>
                  <td>{cItem.falseQuestions}</td>
                  <td>{cItem.emptyQuestions}</td>
                  <td>{parseFloat(netTrue).toFixed(2)}</td>
                  <td>{parseFloat(_questionPoint).toFixed(2)}</td>
                </tr>
              );
            })}
            <tr>
              <th>Total</th>
              <td>{trueQuestions}</td>
              <td>{falseQuestions}</td>
              <td>{emptyQuestions}</td>
              <td>{parseFloat(_netFromAllCourses).toFixed(2)}</td>
              <td>{parseFloat(_pointFromAllCourses).toFixed(2) || 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
export default ExamResultSummary;
