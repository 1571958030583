import React from 'react';
import {connect} from 'react-redux';
import Header from '../Header';
import LeftBar from '../LeftBar';
import RightBar from '../dashboard/RightBar';
import FooterBar from '../FooterBar';

import Spinner from '../Spinner';
import ExamStartByCode from '../dashboard/ExamStartByCode';
import {NotificationContainer} from 'react-notifications';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {changeToResultViewList, pullResultDetail, pullResultList} from '../../actions/result';
import moment from 'moment-timezone';
import Exam from '../Exam/Exam';
import ExamHeader from '../Exam/ExamHeader';
import LeftOptionBar from '../Exam/LeftOptionBar';
import BadgeYellow from '../../styles/img/badge-2-yellow-big.svg';
import Badge1 from '../../styles/img/badge-1.svg';
import ExamResultImage from '../../styles/img/exam-result.jpg';
import {ANS_OPTIONS} from '../../services/constants';
import {changeSearchCourseText, changeShowQuestion} from '../../actions/exam';
import {createNotification} from '../../services/utils';
import ExamResultSummary from '../Exam/ExamResultSummary';

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    view: '',
  };

  componentDidMount() {
    this.props.pullResultList();
    // console.log("Result", this.props);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    //if the question is changed do these...
    if (prevProps.question_show !== this.props.question_show) {
      this.fixBackTheQuestionScrollTop();
    }
  }
  fixBackTheQuestionScrollTop = () => {
    this.myRef.current && (this.myRef.current.scrollTop = 0);
  };
  nextQuestion = () => {
    this.props.dispatch(changeShowQuestion(this.props.question_show + 1));
  };

  previousQuestion = () => {
    this.props.dispatch(changeShowQuestion(this.props.question_show - 1));
  };

  changeFilterCourse = course_id => {
    // console.log("changed filter: ", course_id);
    this.props.dispatch(changeSearchCourseText(course_id));
  };
  showExamInfo = () => {
    // console.log("showExamInfo");
    createNotification(
      'info',
      this.props.exam_instruction,
      this.props.exam_name + ' Hakkında',
      10000
    );
  };
  render() {
    const {result_list, view, show_answer} = this.props;
    // console.log("view", view);
    const courseLength = this.props.courses.length;
    // console.log("om render props", this.props);
    const {
      question_show,
      questions,
      exam_state,
      exam_enter_status,
      filteredQuestions,
      questionIndex,
      courses,
      course_groups,
      course_representation_type,
      examType,
      evaluationName,
      evaluationType,
    } = this.props;

    const totalQuestions = questions.length;
    const filterTypeCourses = course_representation_type === 0 ? courses : course_groups; //replacing the course to course_group if course_representation_type is to show groups
    const shortFilterLength = course_representation_type === 0 ? 12 : 30;
    const totalFilteredQuestions = filteredQuestions.length;
    const questionFavorited = filteredQuestions[question_show - 1]
      ? filteredQuestions[question_show - 1].favorited
      : false;
    console.log(
      'examType,evaluationName,evaluationType,',
      examType,
      evaluationName,
      evaluationType
    );
    // console.log(exam_enter_status, exam_enter_status);
    // console.log("question_show", question_show, question_show - 1 <= 0);
    let trueQuestions = 0;
    let emptyQuestions = 0;
    let falseQuestions = 0;
    let netFromAllCourses = 0;
    let questionPoint = 0;
    let pointFromAllCourses = 0;

    let questionsByCourses = {};
    courses.map(courseItem => {
      courseItem.trueQuestions = 0;
      courseItem.falseQuestions = 0;
      courseItem.emptyQuestions = 0;
      courseItem.questionPoint = 0;

      if (!questionsByCourses[courseItem.id]) {
        questionsByCourses[courseItem.id] = courseItem;
      }
    });
    questions.map(q => {
      if (q.isTrue === 1) {
        questionsByCourses[q.course_id].trueQuestions++;
        questionsByCourses[q.course_id].questionPoint += parseFloat(q.question_point);
        trueQuestions++;
      } else if (q.isTrue === 0) {
        falseQuestions++;
        questionsByCourses[q.course_id].falseQuestions++;
        questionsByCourses[q.course_id].questionPoint -= parseFloat(q.question_point);
      } else {
        emptyQuestions++;
        questionsByCourses[q.course_id].emptyQuestions++;
      }
    });

    // console.log(
    //     "_+_+_+_+__+++_+_+_++_+_",
    //     trueQuestions,
    //     emptyQuestions,
    //     falseQuestions
    // );
    const hasNextQuestion = question_show < totalFilteredQuestions;
    const hasPreviousQuestion = question_show - 1 > 0;
    // const base_url = "http://127.0.0.1:8000/";
    // const base_url = "https://exam.indiviq.online/storage/";
    const shouldBeShortFilterNames =
      course_representation_type === 0 && this.props.courses.length > 5 ? true : false;

    return view == 'detail' && this.props.exam.questions.length > 0 ? (
      <div className="result-page">
        <NotificationContainer />
        <Header />
        <Spinner />
        <div className="row">
          <div className="col-md-12"></div>
        </div>
        <div className="outer-all-wrap">
          <LeftBar />
          {show_answer ? (
            <main>
              <div className="main-content-wrapper">
                <h3 className="tertiary-title">{this.props.exam_name + ' Exam Result'}</h3>
                <div className="exam-question-wrapper">
                  <LeftOptionBar />
                  <div className="exam-question-wrap">
                    <div className="question-header">
                      <div className="all-question-btns">
                        <button
                          className={`box-btn ${
                            this.props.exam.searchCourseText === '' ? 'active' : ''
                          }`}
                          onClick={this.changeFilterCourse.bind(this, '')}>
                          Tümü
                        </button>

                        {filterTypeCourses &&
                          filterTypeCourses.map((course, key) => {
                            return (
                              <button
                                key={course.id}
                                className={`box-btn ${
                                  parseInt(this.props.exam.searchCourseText) === course.id
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={this.changeFilterCourse.bind(this, course.id)}>
                                {shouldBeShortFilterNames
                                  ? course.short_name.length > shortFilterLength
                                    ? course.short_name.substring(0, shortFilterLength - 2) + '..'
                                    : course.short_name
                                  : course.short_name}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                    <div className="question-content"  ref={this.myRef}>
                      <div className="single-question-scroll-wrap">
                        <SimpleBar className="simple-bar">
                          {this.props.question && (
                            <div className="single-question">
                              <div className="question-sl">
                                <span className="question-number">
                                  <span className="question-course">
                                    {this.props.question &&
                                      this.props.courses.map(cours => {
                                        const Cname =
                                          cours.id == this.props.question.course_id
                                            ? cours.short_name + ' sorusu'
                                            : '';
                                        return Cname;
                                      })}
                                  </span>
                                  {this.props.question_show}.
                                </span>

                                <img
                                  src={
                                    // questionFavorited
                                    //     ? BadgeYellow
                                    //     :
                                    Badge1
                                  }
                                  alt=""
                                  // onClick={
                                  //     this.toggleFavorite.bind(
                                  //     this,
                                  //     !questionFavorited
                                  // )
                                  // }
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                              <div className="question-text-wrap">
                                <div>
                                  <div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: this.props.question.root,
                                      }}
                                      className="question-text"
                                    />

                                    <div className="answer-optons">
                                      {this.props.question.answers &&
                                        this.props.question.answers.map((ans, answer_key) => {
                                          return (
                                            <label
                                              style={
                                                ans.true == 1
                                                  ? {
                                                      borderColor: 'green',
                                                      borderWidth: '3px',
                                                    }
                                                  : {}
                                              }
                                              htmlFor={'radio' + ans.id}
                                              key={ans.id}
                                              className={
                                                this.props.question.answer === ans.id
                                                  ? 'active'
                                                  : ''
                                              }>
                                              <input
                                                type="radio"
                                                name={'radio' + ans.id}
                                                id={'radio' + ans.id}
                                                value={'radio' + ans.id}
                                                readOnly
                                                checked={this.props.question.answer === ans.id}
                                                // onChange={this.answerTheQuestion.bind(
                                                //     this,
                                                //     ans,
                                                //     ANS_OPTIONS[
                                                //         answer_key
                                                //         ],
                                                //     ans.true,
                                                //     ans.id
                                                // )}
                                              />
                                              <span className="mcq-option">
                                                {ANS_OPTIONS[answer_key]}
                                              </span>

                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: ans.answer,
                                                }}></div>
                                            </label>
                                          );
                                        })}
                                    </div>
                                  </div>

                                  {/*<h4>Yukarıdaki boşluğa hangisi gelmelidir?</h4>*/}
                                </div>
                              </div>
                            </div>
                          )}
                        </SimpleBar>
                        {/* </div> */}
                        <div className="twin-btn-wrap noselect">
                          <div className="exam-bottom">
                            {/* <div
                                                            className="input-inside exam-name"
                                                            onClick={
                                                                this
                                                                    .showExamInfo
                                                            }
                                                        >
                                                            <label>
                                                                {
                                                                    this.props
                                                                        .exam_name
                                                                }
                                                            </label>
                                                        </div> */}
                            {/*<span className="questions-summary">*/}
                            {/*    {`Boş ${emptyQuestions} \u00A0\u00A0 | \u00A0\u00A0 Cevaplanmış: ${trueQuestions +*/}
                            {/*    falseQuestions} `}*/}
                            {/*</span>*/}
                          </div>
                          <a
                            className={
                              'box-btn style-2 ' +
                              (hasPreviousQuestion
                                ? ' twin-btn-wrap-enabled'
                                : 'twin-btn-wrap-disabled')
                            }
                            onClick={hasPreviousQuestion ? this.previousQuestion : undefined}>
                            Önceki Soru
                          </a>
                          <a
                            className={
                              'box-btn style-2 ' +
                              (hasNextQuestion
                                ? 'twin-btn-wrap-enabled'
                                : ' twin-btn-wrap-disabled')
                            }
                            onClick={hasNextQuestion ? this.nextQuestion : undefined}>
                            Sonraki Soru
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ExamResultSummary
                    evaluationType={evaluationType}
                    questionPoint={questionPoint}
                    questionsByCourses={questionsByCourses}
                    netFromAllCourses={netFromAllCourses}
                    pointFromAllCourses={pointFromAllCourses}
                    trueQuestions={trueQuestions}
                    falseQuestions={falseQuestions}
                    emptyQuestions={emptyQuestions}
                  />
                  {/* <div className="exam-over-summary">
                                        <h5 className="d-block">
                                            Sınav Özetiniz:
                                        </h5>
                                        <br />

                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Dersler:</th>
                                                    <th>Doğru:</th>
                                                    <th>Yanlış</th>
                                                    <th>Boş:</th>
                                                    <th>Net:</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(
                                                    questionsByCourses
                                                ).map(([key, cItem]) => {
                                                    let netTrue =
                                                        cItem.falseQuestions > 0
                                                            ? cItem.trueQuestions -
                                                              (
                                                                  cItem.falseQuestions /
                                                                  4
                                                              ) //TODO Set a variable on backend for this.
                                                                  .toFixed(2)
                                                            : cItem.trueQuestions;

                                                    netTrue = //check if net is small than 0
                                                        netTrue < 0
                                                            ? 0
                                                            : netTrue;
                                                    netFromAllCourses += netTrue;

                                                    return (
                                                        <tr key={key}>
                                                            <th>
                                                                {
                                                                    cItem.short_name
                                                                }
                                                            </th>
                                                            <td>
                                                                {
                                                                    cItem.trueQuestions
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    cItem.falseQuestions
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    cItem.emptyQuestions
                                                                }
                                                            </td>
                                                            <td>{netTrue}</td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <th>TOPLAM</th>
                                                    <td>{trueQuestions}</td>
                                                    <td>{falseQuestions}</td>
                                                    <td>{emptyQuestions}</td>
                                                    <td>{netFromAllCourses}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                </div>
              </div>
            </main>
          ) : (
            <main className="main-content-wrapper exam-pge exam-end-pge">
              <div className="exam-question-wrapper style-2">
                <div className="exam-over-summary">
                  <p>
                    <strong className="d-block">Your Exam Summary:</strong>
                    Total Question:
                    <strong>{' ' + totalQuestions}</strong>
                    <br />
                    {/* Doğru Cevap: */}
                    Correct Answer:
                    <strong>{' ' + trueQuestions}</strong>
                    <br />
                    {/* Yanlış Cevap: */}
                    Incorrect Answer:
                    <strong>{' ' + falseQuestions}</strong>
                    <br />
                    {/* Boş Bırakılan Soru Sayısı: */}
                    Empty Questions:
                    <strong>{' ' + emptyQuestions}</strong>
                  </p>

                  <button
                    className="btn btn-lg btn-success form-control"
                    onClick={() => {
                      this.props.dispatch(changeToResultViewList());
                    }}>
                    Back
                  </button>
                </div>
              </div>
            </main>
          )}
        </div>
      </div>
    ) : (
      <div>
        <NotificationContainer />

        <Header />
        <Spinner />
        <div className="outer-all-wrap">
          <LeftBar />
          <main>
            <div className="main-content-wrapper">
              <div className="row no-margin">
                <div className="col-lg-9 no-padding">
                  <div className="main-left-wrapper">
                    <h3 className="tertiary-title">Results</h3>
                    <div className="content-block">
                      <div className="user-guide-wrap">
                        {result_list.map((item, key) => {
                          return (
                            <div className="single-guide bdr-bottom" key={key}>
                              <div className="guide-img exam-result-image">
                                <img
                                  src={
                                    // questionFavorited
                                    //     ? BadgeYellow
                                    //     :
                                    ExamResultImage
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="gude-content">
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault();
                                    this.props.pullResultDetail(item.session_id);
                                  }}>
                                  {item.exam_name}
                                </a>
                                <p>
                                  Sınav Başlangıç Tarihi :{' '}
                                  {moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')}
                                </p>
                                <p>
                                  Sınav Bitiş Tarihi:{' '}
                                  {moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <FooterBar />
                </div>
                <RightBar />
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    questions,
    searchCourseText,
    exam_enter_status,
    question_show,
    course_representation_type, //0 Represent as course , 1 - Show as group, 2 Both
  } = state.exam;
  let questionsToShowAfterCourseFilters;
  if (course_representation_type === 1) {
    console.log('here we go course_representation_type is 1 ');
    questionsToShowAfterCourseFilters =
      searchCourseText === ''
        ? questions
        : questions.filter(question => question.course_group_id == searchCourseText);
  } else {
    questionsToShowAfterCourseFilters =
      searchCourseText === ''
        ? questions
        : questions.filter(question => question.course_id == searchCourseText);
  }
  const filteredQuestions = questionsToShowAfterCourseFilters;
  // searchCourseText === ""
  //     ? questions
  //     : questions.filter(
  //           question => question.course_id == searchCourseText
  //       );
  const question = filteredQuestions[question_show - 1];
  const questionIndex = state.exam.questions.findIndex(q => q.id === question.id);

  return {
    result_list: state.result.list,
    view: state.result.view,
    courses: state.exam.courses,
    course_groups: state.exam.course_groups,
    questions,
    question_show,
    exam: state.exam,
    exam_name: state.exam.name,
    examType: state.exam.exam_type_name,
    evaluationName: state.exam.evaluation_type_name,
    evaluationType: state.exam.evaluation_type,
    exam_instruction: state.exam.instruction,
    exam_start_time: state.exam.exam_start_time,
    // questions: state.exam.questions,
    exam_state: state.exam.exam_state,
    exam_status: state.exam.status,
    exam_enter_status,
    searchCourseText,
    filteredQuestions,
    question,
    questionIndex,
    result: state.exam.result,
    show_answer: state.exam.show_answer,
    course_representation_type,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    pullResultList: () => dispatch(pullResultList()),
    pullResultDetail: session_id => dispatch(pullResultDetail(session_id)),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
