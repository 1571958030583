import React from "react";
import { connect } from "react-redux";
import PowerIcon from "../../styles/img/power-icon.svg";
import Logo from "../../styles/img/single-logo.png";
import Timer2 from "../../styles/img/timer-2.svg";
import TimerRed from "../../styles/img/timer-red.svg";
import { Link, withRouter } from "react-router-dom";
import { storeAnswers, submitExam } from "../../actions/exam";
import {
    createNotification,
    get_formatted_answers
} from "../../services/utils";
import { saveAnswers } from "../../services/api";

// import { logout } from "../../services/api";

class ExamHeader extends React.Component {
    state = {
        totalRemaining: 0,
        total: null,
        hours: 0,
        minutes: 0,
        seconds: 0,
        endtime: null,
        timeInterval: null
    };
    showExamInfo = () => {
        // console.log("timeInterval", this.state.timeInterval);
        createNotification(
            "info",
            this.props.exam_instruction,
            this.props.exam_name + " Hakkında",
            10000
        );
    };
    finishExam = () => {
        clearInterval(this.state.timeInterval);
        let { exam_id, questions, session_id } = this.props;
        //TODO If user dont answer any question, should we submit it to the server????
        this.props.dispatch(
            submitExam(exam_id, session_id, get_formatted_answers(questions))
        );
        // this.props.exitHandler()

        // this.props.finishExam('ok');
        // this.props.history.push(`/exam?end/`);
    };

    add_minutes = (dt, minutes) => {
        return new Date(dt.getTime() + minutes * 60000);
    };

    sendAnswersAfterInterval = () => {
        setInterval(() => {
            console.log("setTimeoutRUN---------------------");
            let { exam_id, questions, temp_ques_ans, session_id } = this.props;
            if (
                temp_ques_ans.length > 0 &&
                (this.props.exam_status == 0 || this.props.exam_status == 2)
            ) {
                console.log("DIPATCHING EVENT submitExam", session_id);
                this.props.dispatch(
                    storeAnswers(
                        exam_id,
                        session_id,
                        get_formatted_answers(questions, temp_ques_ans)
                    )
                );
            }
        }, 6000);
    };

    getRemainingTime = () => {
        const { endtime } = this.state;
        const milliseconds = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor((milliseconds / 1000) % 60);
        const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
        const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
        const warningHour = 0; // TODO add an option on the backend for warning time. Also maybe for the last warning message.
        const warningSec = 30;
        const warningMin = 0;
        if (
            minutes === warningMin &&
            seconds === warningSec &&
            minutes === warningHour
        ) {
            createNotification(
                "info",
                "Son " +
                    warningSec +
                    " saniye! " +
                    warningSec +
                    " saniye sonra sınav otomatik olarak kapanacaktır.",
                "Dikkat",
                6000
            );
        }
        if (milliseconds <= 0) {
            // createNotification(
            //     "info",
            //     "Süreniz dolduğu için sınavınız otomatik olarak bitmiştir.",
            //     "Dikkat",
            //     5000
            // );
            this.finishExam();
        }

        this.setState({
            totalRemaining: milliseconds,
            seconds: seconds,
            minutes: minutes,
            hours: hours,
            total: this.state.total === null ? milliseconds : this.state.total //Only once set the thime at the beginning
        });
    };

    componentWillUnmount() {
        clearInterval(this.state.timeInterval);
        window.removeEventListener("beforeunload", this.handleLeavePage);
    }

    componentDidMount() {
        // console.log("exam header");
        if (this.props.exam_time === null) {
            clearInterval(this.state.timeInterval);
            window.removeEventListener("beforeunload", this.handleLeavePage);
            this.props.history.push(`/home`); // Mst if user refreshes the page or enters the url , it redirects it to the home page.
            return;
        }
        process.env.NODE_ENV === "production" &&
            window.addEventListener("beforeunload", this.handleLeavePage);
        const timeinterval = setInterval(this.getRemainingTime, 1000);
        this.setState({
            endtime: this.add_minutes(
                new Date(this.props.exam_start_time),
                this.props.exam_time
            ),
            timeInterval: timeinterval
        });

        // userDetail.then(response => {
        //     console.log(response);
        //     this.setState({
        //         name: response.success.name,
        //         email: response.success.email
        //     });
        // });
        // console.log("props", this.props);
        this.sendAnswersAfterInterval();
    }

    handleLeavePage = e => {
        const confirmationMessage =
            "Sınavdan çıkmak istediğinizden emin misiniz:";
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        // console.log(" e.returnValue", e);
        return confirmationMessage; // Gecko, WebKit, Chrome <34
    };

    render() {
        const { history, exitStatus } = this.props;
        exitStatus && this.finishExam();
        const {
            seconds,
            minutes,
            hours,
            totalRemaining,
            endtime,
            total
        } = this.state;
        const thresholdSecond = total / 10; //When the counter gets red, default 60 last seconds
        const redStyle =
            total !== null ? totalRemaining <= thresholdSecond : false;
        // console.log(
        //     "Math.floor(total / totalRemaining)",
        //     totalRemaining / total,
        //     Math.floor((totalRemaining / total) * 100),
        //     100 - Math.floor((totalRemaining / total) * 100),
        //     total,
        //     totalRemaining
        // );
        const progressStyleMaxWidth = 124;
        const progressBarPercent =
            totalRemaining > 0
                ? progressStyleMaxWidth -
                  Math.floor((totalRemaining / total) * progressStyleMaxWidth)
                : 0;
        // console.log("progressBarPercent", progressBarPercent);
        const counterStyle = redStyle
            ? "countdown__timer js-countdown-seconds countdown_red"
            : "countdown__timer js-countdown-seconds ";
        return (
            <header className="header-area">
                <div className="container-fluid">
                    <div className="header-f-wrap style-2">
                        <div className="exam-header-left">
                            <a href="#" className="logo">
                                <img src={Logo} alt="" />
                            </a>
                            {/* <div className="mobile-search d-md-none d-xl-none">
                                <i className="fal fa-search"></i>
                            </div>
                            <div>
                                <span className="sc-icon">
                                    <i className="fal fa-search"></i>
                                </span>
                            </div> */}
                            <div
                                className="input-inside exam-name big-screen-exam-title"
                                onClick={this.showExamInfo}
                            >
                                <h2>{this.props.exam_name}</h2>
                                {/* <span className="sc-icon-small">
                                    <i className="fal fa-info-circle fa-xs"></i>
                                </span> */}
                            </div>
                        </div>
                        {/* <!-- ./exam-header-left --> */}

                        <div className="exam-header-right">
                            {/* New Settings Icon */}
                            {/* <div className="settings-area">
                                <div className="dropdown">
                                    <a
                                        className="btn dropdown-toggle"
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 172 172"
                                            style={{fill:"#000000"}}
                                        >
                                            <g
                                                fill="none"
                                                fillRule="nonzero"
                                                stroke="none"
                                                strokeWidth="1"
                                                strokeLinecap="butt"
                                                strokeLinejoin="miter"
                                                strokeMiterlimit="10"
                                                strokeDasharray=""
                                                strokeDashoffset="0"
                                                fontFamily="none"
                                                fontWeight="none"
                                                fontSize="none"
                                                textAnchor="none"
                                                style={{mixBlendMode: "normal"}}
                                            >
                                                <path
                                                    d="M0,172v-172h172v172z"
                                                    fill="none"
                                                ></path>
                                                <g fill="#333555">
                                                    <path d="M162.2304,73.00024l-20.3304,-3.32304c-1.19024,-4.07984 -2.81736,-8.00144 -4.85384,-11.7132l11.868,-16.91448c0.95976,-1.36568 0.79464,-3.22672 -0.38528,-4.41008l-13.37816,-13.37128c-1.19368,-1.19024 -3.07192,-1.34504 -4.44104,-0.35776l-16.65992,11.97464c-3.74616,-2.07088 -7.70216,-3.7152 -11.80608,-4.90888l-3.54664,-20.24784c-0.28896,-1.64432 -1.71656,-2.84832 -3.3884,-2.84832h-18.92c-1.6856,0 -3.12352,1.2212 -3.39528,2.88616l-3.28864,20.13776c-4.128,1.1868 -8.09088,2.81392 -11.82328,4.85728l-16.6152,-11.868c-1.37256,-0.9804 -3.24048,-0.82216 -4.43416,0.36464l-13.37128,13.37128c-1.17992,1.17992 -1.34504,3.03752 -0.38528,4.4032l11.69256,16.72872c-2.0812,3.7668 -3.73928,7.75376 -4.94672,11.9024l-20.05864,3.34024c-1.65808,0.2752 -2.87584,1.71312 -2.87584,3.39184v18.92c0,1.6684 1.19712,3.096 2.838,3.3884l20.05864,3.55696c1.20056,4.13832 2.85864,8.12528 4.94672,11.9024l-11.83704,16.55672c-0.97696,1.36568 -0.82216,3.24048 0.36464,4.43416l13.37472,13.38504c1.17992,1.17992 3.04096,1.34504 4.40664,0.38528l16.7528,-11.73384c3.75992,2.06744 7.73312,3.70832 11.8508,4.89856l3.35744,20.16184c0.27176,1.65464 1.70624,2.86896 3.3884,2.86896h18.92c1.6684,0 3.096,-1.19712 3.38496,-2.838l3.5948,-20.2616c4.12456,-1.21432 8.07712,-2.86552 11.7992,-4.9364l16.8732,11.83704c1.36912,0.96664 3.22672,0.79808 4.41008,-0.38184l13.37472,-13.38504c1.19024,-1.19368 1.34504,-3.07536 0.35776,-4.44448l-12.03312,-16.70808c2.03992,-3.7152 3.66016,-7.64368 4.84008,-11.72352l20.35792,-3.57416c1.64776,-0.28896 2.84488,-1.72 2.84488,-3.3884v-18.92c0.00344,-1.6856 -1.21776,-3.12352 -2.88272,-3.39528zM86,110.08c-13.29904,0 -24.08,-10.78096 -24.08,-24.08c0,-13.29904 10.78096,-24.08 24.08,-24.08c13.29904,0 24.08,10.78096 24.08,24.08c0,13.29904 -10.78096,24.08 -24.08,24.08z"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>

                                    <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                    >
                                        <a className="dropdown-item" href="#">
                                            <span>+</span> Font Büyüt
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            <span>-</span> Font Küçült
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="exam-timer-wrap">
                                <img
                                    src={redStyle ? TimerRed : Timer2}
                                    alt=""
                                    className="exam-timer-icon"
                                />

                                {/* <div className="timer-progress-wrap" style={{background:"linear-gradient(90deg, #888999 12.57%, rgba(196, 196, 196, 0) 22.72%)"}}>
                                    <span></span>
                                </div> */}

                                <div className="progress progress-exam">
                                    <div
                                        className={
                                            redStyle
                                                ? " progress-bar progressBarStyleRed"
                                                : " progress-bar progressBarStyle"
                                        }
                                        role="progressbar"
                                        style={{ width: progressBarPercent }}
                                        aria-valuenow={{ progressBarPercent }}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                                <div className="countdown" id="js-countdown">
                                    <div className="countdown__item">
                                        <div
                                            className={counterStyle}
                                            aria-labelledby="hour-countdown"
                                        >
                                            {("0" + hours).slice(-2)}
                                        </div>

                                        <div
                                            className="countdown__label"
                                            id="hour-countdown"
                                        >
                                            hour
                                        </div>
                                    </div>

                                    <div className="countdown__item">
                                        <div
                                            className={counterStyle}
                                            aria-labelledby="minute-countdown"
                                        >
                                            {("0" + minutes).slice(-2)}
                                        </div>

                                        <div
                                            className="countdown__label"
                                            id="minute-countdown"
                                        >
                                            minute
                                        </div>
                                    </div>

                                    <div className="countdown__item">
                                        <div
                                            className={counterStyle}
                                            aria-labelledby="second-countdown"
                                        >
                                            {("0" + seconds).slice(-2)}
                                        </div>

                                        <div
                                            className="countdown__label"
                                            id="second-countdown"
                                        >
                                            second
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="box-btn style-2 in-header"
                                onClick={this.props.exitHandler}
                            >
                                <img src={PowerIcon} alt="" />{" "}
                                <span className="m-purpose">
                                    {/* Sınavı Bitir */}
                                    Exit Exam
                                    </span>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        exam_time: state.exam.exam_time,
        exam_start_time: state.exam.exam_start_time,
        exam_name: state.exam.name,
        exam_instruction: state.exam.instruction,
        questions: state.exam.questions,
        exam_id: state.exam.exam_id,
        temp_ques_ans: state.exam.temp_ques_ans,
        exam_status: state.exam.status,
        session_id: state.exam.session_id
    };
};

export default withRouter(connect(mapStateToProps)(ExamHeader));
