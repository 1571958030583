import React from "react";
import { connect } from "react-redux";
import QuestionMark from "../../styles/img/question-mark.svg";
import Alarm from "../../styles/img/alarm.svg";

import { createNotification } from "../../services/utils";
import { setShowIntroductionData } from "../../actions/dashboard";

class ActiveExams extends React.Component {
    render() {
        return (
            <div className="content-block-wrap">
                <h3 className="tertiary-title">Active Exams</h3>
                <div className="content-block">
                    <div className="table-scroll-wrap">
                        <table className="table exam-table">
                            <tbody>
                                {this.props.activeExams.length ? (
                                    this.props.activeExams.map(
                                        (activeExam, activeExam_key) => {
                                            return (
                                                <tr key={activeExam_key}>
                                                    <td>
                                                        <div className="active-exam-wrap">
                                                            <h4 className="exam-title">
                                                                {
                                                                    activeExam.name
                                                                }
                                                            </h4>
                                                            <p>
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            QuestionMark
                                                                        }
                                                                        alt=""
                                                                    />{" "}
                                                                    {
                                                                        activeExam.questions_count
                                                                    }{" "}
                                                                    Soru
                                                                </span>{" "}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            Alarm
                                                                        }
                                                                        alt=""
                                                                    />{" "}
                                                                    {
                                                                        activeExam.exam_time
                                                                    }{" "}
                                                                    dk
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="box-btn start"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.setShowIntroductionData(
                                                                    {
                                                                        showingIntroduction: true,
                                                                        examIdForIntroduction:
                                                                            activeExam.id,
                                                                        examCodeForIntroduction: null
                                                                    }
                                                                );
                                                                // this.examStartHandler(
                                                                //     activeExam.id
                                                                // );
                                                            }}
                                                        >
                                                            Start
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                ) : (
                                    <tr>
                                        <td
                                        // style={{borderBottom: 0}}
                                        >
                                            <div
                                                className="active-exam-wrap"
                                                style={{ textAlign: "left" }}
                                            >
                                                <p>
                                                    {" "}
                                                    "Aktif sınav
                                                    bulunmamaktadır."
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeExams: state.dashboard.activeExams
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShowIntroductionData: data => dispatch(setShowIntroductionData(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveExams);
