import { dashboardData } from "../services/api";
import { GET_DASHBOARD_DATA, SHOW_EXAM_INTRODUCTION } from "../actions/actions";
import { spinner } from "./spinner";
import { startTimer } from "./timer";

export const pullDashboardData = () => {
    // console.log("pullDashboardData calling.....");
    return dispatch => {
        dispatch(spinner(true));
        dashboardData()
            .then(function(data) {
                // console.log('j sdkshkjs hjks')
                dispatch(getDashboardData(data));
                dispatch(spinner(false));
                // dispatch(startTimer());
            })
            .catch(function(err) {
                console.info("in tbe error block");
            })
            .finally(() => {});
    };
};

export const getDashboardData = data => {
    return {
        type: GET_DASHBOARD_DATA,
        payload: data
    };
};

export const setShowIntroductionData = data => {
    console.log('setShowIntroductionData data', data)
    return {
        type: SHOW_EXAM_INTRODUCTION,
        payload: data
    };
};
