import React from "react";
import { connect } from "react-redux";
import Header from "../Header";
import LeftBar from "../LeftBar";
import ActiveExams from "./ActiveExams";
import YourGuide from "./YourGuide";
import UpcomingExams from "./UpcomingExams";
import RightBar from "./RightBar";
import FooterBar from "../FooterBar";
import { spinner } from "../../actions/spinner";
import {
    pullDashboardData,
    setShowIntroductionData
} from "../../actions/dashboard";
import Spinner from "../Spinner";
import ExamStartByCode from "./ExamStartByCode";
import ExamIntroduction from "./ExamIntroduction";
import { NotificationContainer } from "react-notifications";

class Index extends React.Component {
    state = {
        // showExamIntroduction: false,
        // examIdForIntroduction: null,
        // examCodeForIntroduction:null
    };
    componentWillMount() {
        // console.log('localStorage.auth_token',localStorage.auth_token);
        if (
            localStorage.auth_token == "null" ||
            localStorage.auth_token === undefined
        ) {
            window.location = "/login";
        }
        window.scrollTo(0, 0);
    }

    setExamIntroduction = (examIdForIntroduction = null) => {
        this.props.setShowIntroductionData({
            showingIntroduction: true,
            examIdForIntroduction,
            examCodeForIntroduction: null
        });
    };
    setExamIntroductionByCode = (examCodeForIntroduction = null) => {
        this.props.setShowIntroductionData({
            showingIntroduction: true,
            examIdForIntroduction: null,
            examCodeForIntroduction
        });
    };

    componentDidMount() {
        this.props.setShowIntroductionData({
            showingIntroduction: null,
            examIdForIntroduction: null,
            examCodeForIntroduction: null
        });

        this.checkPullData();
        // console.log("dashboardda", this.props);
    }
    checkPullData = async () => {
        const pullStatus = await this.props.pullDashboardData();
        // console.log("pullStatus", pullStatus);
    };

    render() {
        // console.log(
        //     "this.props.logged_in",
        //     localStorage.auth_token,
        //     localStorage.auth_token == 'null'
        // );
        const { showingIntroduction } = this.state;
        if (localStorage.auth_token == "null") {
            return <div>Not Logged In</div>;
        } else {
            return (
                <div className="home-page">
                    <NotificationContainer />

                    <Header />
                    <Spinner />

                    <div className="outer-all-wrap">
                        <LeftBar />
                        <main>
                            <div className="main-content-wrapper">
                                <div className="row no-margin">
                                    <div className={this.props.showingIntroduction?"col-lg-12 no-padding":"col-lg-9 no-padding" }>
                                        <div className="main-left-wrapper">
                                            {this.props.showingIntroduction ? (
                                                <ExamIntroduction />
                                            ) : (
                                                <React.Fragment>
                                                    <ExamStartByCode />
                                                    <ActiveExams />
                                                    <UpcomingExams />
                                                    <YourGuide />
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <FooterBar />
                                    </div>
                                    {!this.props.showingIntroduction && (
                                        <RightBar />
                                    )}
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.login.logged_in,
        activeExams: state.dashboard.activeExams,
        showingIntroduction: state.dashboard.showingIntroduction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        pullDashboardData: () => dispatch(pullDashboardData()),
        setShowIntroductionData: data => dispatch(setShowIntroductionData(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
