import React from "react";
import { connect } from "react-redux";
import S1 from "../../styles/img/s-1.png";
import S2 from "../../styles/img/s-2.png";
import S3 from "../../styles/img/s-3.png";
import S4 from "../../styles/img/s-4.png";
import { pullNewsData } from "../../actions/news";

class RightBar extends React.Component {
    componentDidMount() {
        this.props.pullNewsData();
    }

    render() {
        const { news } = this.props;
        // console.log(news);
        // const base_url = "http://127.0.0.1:8000/storage/";
        const base_url = "https://exam.indiviq.online/storage/";
        return (
            <div className="col-lg-3 no-padding">
                <div className="right-sidebar-wrap">
                    <div className="right-sidebar-block">
                        {/* <h3 className="tertiary-title">Takvim</h3> */}
                        <div className="top-calender">
                            <div className="app">
                                <div className="app__main">
                                    <div className="calendar">
                                        <div id="calendar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cs-calender">
                            <div className="month">
                                <ul>
                                    {/* <li className="prev">&#10094;</li>
                                    <li className="next">&#10095;</li> */}
                                    <li>
                                        March
                                        <br />
                                        <span style={{ fontSize: "13px" }}>
                                            2024
                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <ul className="weekdays">
                                <li>Pt</li>
                                <li>Sa</li>
                                <li>Çr</li>
                                <li>Pr</li>
                                <li>Cm</li>
                                <li>Cmt</li>
                                <li>Pz</li>
                            </ul>

                            <ul className="days">
                                <li>1</li>
                                <li>2</li>
                                <li>3</li>
                                <li>4</li>
                                <li>5</li>
                                <li>6</li>
                                <li>7</li>
                                <li>8</li>
                                <li>9</li>
                                <li>
                                   10
                                </li>
                                <li>11</li>
                                <li>12</li>
                                <li>13</li>
                                <li>14</li>
                                <li>15</li>
                                <li>16</li>
                                <li>17</li>
                                <li>18</li>
                                <li>19</li>
                                <li>20</li>
                                <li>21</li>
                                <li>22</li>
                                <li>23</li>
                                <li>24</li>
                                <li>25</li>
                                <li>26</li>
                                <li>
                                <span className="active">27</span>
                                </li>
                                <li>28</li>
                                <li>29</li>
                                <li>30</li>
                                <li>31</li>
                            </ul>
                        </div>
                    </div>
                    <div className="right-sidebar-block">
                        <h3 className="tertiary-title">Latest News</h3>
                        {news.news
                            ? news.news.map((singleNews, singleNews_key) => {
                                  let d = new Date(
                                      Date.parse(singleNews.updated_at)
                                  );
                                  let dateString =
                                      ("0" + d.getDate()).slice(-2) +
                                      "-" +
                                      ("0" + (d.getMonth() + 1)).slice(-2) +
                                      "-" +
                                      d.getFullYear();
                                  return (
                                      <React.Fragment key={singleNews_key}>
                                          <div className="single-guide in-sidebar">
                                              <div className="guide-img">
                                                  <img
                                                      src={
                                                          base_url +
                                                          singleNews.picture
                                                      }
                                                      alt=""
                                                  />
                                              </div>
                                              <div className="gude-content">
                                                  <a href={'haber/' + singleNews.slug}>
                                                      {singleNews.title}
                                                  </a>
                                                  <p>{dateString}</p>
                                              </div>
                                          </div>
                                      </React.Fragment>
                                  );
                              })
                            : "Haber yok"}
                    </div>
                    <div className="footer-text bdr-top d-lg-none d-xl-none">
                        Uzman Sınav © 2020 <span>|</span> &nbsp{" "}
                        <a href="mavirobot.com">Hakkımızda</a>{" "}
                        <a href="#">İletişim</a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        news: state.news
    };
};
const mapDispatchToProps = dispatch => {
    return {
        pullNewsData: () => dispatch(pullNewsData())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightBar);
