import React from "react";
import { connect } from "react-redux";
import { userDetail } from "../services/api";
import { spinner } from "../actions/spinner";
import Logo from "../styles/img/indiviexam2.png";
import Bell from "../styles/img/bell.svg";
import User from "../styles/img/user.png";
import User2 from "../styles/img/user-2.png";
import UserDefault from "../styles/img/user-default.svg";
import { Link, withRouter } from "react-router-dom";
import { logout } from "../services/api";

class Header extends React.Component {
    state = {
        name: "",
        email: ""
    };
    componentDidMount() {
        // console.log("userDetail", userDetail);
        userDetail
            .then(response => {
                // console.log(response);
                this.setState({
                    name: response.success.name,
                    email: response.success.email
                });
            })
            .catch(err => {
                // this.props.history.push(`/login`);
            });
    }
    // componentDidMount() {

    // }
    logOutHandler = e => {
        e.preventDefault();
        logout()
            .then(response => {
                // console.log(response);
                // console.log("logout done");
                localStorage.auth_token = null;
                this.props.history.push(`/login`);
            })
            .catch(error => {
                this.setState({
                    error_message: "Hata!"
                });
            });
    };

    render() {
        const { history } = this.props;
        return (
            <header className="header-area">
                <div className="container-fluid">
                    <div className="header-f-wrap">
                        <a
                            href="#"
                            className="logo"
                            onClick={() => {
                                this.props.history.push(`/home`);
                            }}
                        >
                            <img src={Logo} alt="" />
                        </a>
                        <ul className="user-part">
                            {/* <li><a href="#"><img src={Bell} alt=""/></a>
                                <ul className="submenu notification">
                                    <li>
                                        <a href="#" className="white">Size 2 sınav
                                            atandı. <span>1 gün önce</span></a>
                                    </li>
                                    <li>
                                        <a href="#" className="white">Rehberinizden mesaj
                                            var. <span>3 gün önce</span></a>
                                    </li>
                                    <li>
                                        <a href="#">Rehberinizden mesaj var. <span>30.10.2019</span></a>
                                    </li>
                                </ul>
                            </li> */}
                            <li>
                                <a href="#">
                                    <img src={UserDefault} alt="" />
                                </a>
                                <ul className="submenu">
                                    <li>
                                        <div className="user-img">
                                            <img src={UserDefault} alt="" />
                                            <h4>{this.state.name} </h4>
                                            <p className="mail">
                                                {this.state.email}{" "}
                                            </p>
                                            <button className="box-btn style-2">
                                                Profilim
                                            </button>
                                            <button
                                                className="box-btn style-3"
                                                // onClick={e => {
                                                //     e.preventDefault();
                                                //     history.push("/login");
                                                // }}
                                                onClick={this.logOutHandler}
                                            >
                                                Çıkış
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default withRouter(connect(mapStateToProps)(Header));
