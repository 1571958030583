import React from "react";

class FooterBar extends React.Component {
    render() {
        return (
            <footer className="footer-text bdr-top">
             {/* d-none d-lg-block */}
             indivi Exam © 2023 &nbsp; by Indivitech &nbsp;
                &nbsp; <span>|</span>
                <a href="#">Hakkımızda</a> <a href="#">İletişim</a>
            </footer>
        );
    }
}



export default FooterBar;
