import thunk from 'redux-thunk';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import spinnerReducer from "./spinner_reducer";
import dashboard_reducer from "./dashboard_reducer";
import exam_reducer from "./exam_reducer";
import timer_reducer from './timer_reducer';
import news_reducer from './news_reducer';
import login_reducer from './login_reducer';
import result_reducer from "./result_reducer";

export default () => {

console.log(process.env.NODE_ENV);

    const store = createStore(
        combineReducers({
            spinner: spinnerReducer,
            dashboard:dashboard_reducer,
            exam:exam_reducer,
            timer:timer_reducer,
            news:news_reducer,
            login:login_reducer,
            result:result_reducer
        }),

        compose(applyMiddleware(thunk)
            ,
            process.env.NODE_ENV==='development' && window.navigator.userAgent.includes('Chrome') ?
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__():compose
        )
    );

    return store;
};
