import React from "react";
import { connect } from "react-redux";
import Header from "../Header";
import LeftBar from "../LeftBar";
import { spinner } from "../../actions/spinner";
import Spinner from "../Spinner";
import FooterBar from "../FooterBar";
import { withRouter } from "react-router-dom";
import RightBar from "./../dashboard/RightBar";
import { pullSinglePost } from "../../actions/news";

class SingleNews extends React.Component {
    state = {
        name: ""
    };
    componentDidMount() {
        const { match } = this.props;
        console.log("called api singleNews", match.params.slug);

        match.params.slug && this.props.pullSinglePost(match.params.slug);
    }

    render() {
        const base_url = "https://exam.indiviq.online/storage/";
        const { match, history, currentPost } = this.props;
        // console.log(news)
        // const currentNews=news.filter(sn=>sn.slug===match.params.slug);
        // console.log(currentNews);
        console.log("currentPost", currentPost);

        return (
            <div className="all-news-page">
                <Header />
                <Spinner />
                <div className="outer-all-wrap">
                    <LeftBar />
                    <main>
                        <div className="main-content-wrapper">
                            <div className="row no-margin">
                                <div className="col-lg-9 no-padding">
                                    <div className="main-left-wrapper">
                                        <div className="content-block-wrap">
                                            <div className="primary-title">
                                                <h2>
                                                    {currentPost &&
                                                        currentPost.title}
                                                </h2>
                                                <div className="gude-content">
                                                    <p>
                                                        {" "}
                                                        {currentPost &&
                                                            currentPost.updated_at}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="content-block news-detail">
                                                <div className="news-big-img text-center">
                                                    <img
                                                        src= {currentPost &&
                                                            base_url + currentPost.picture}
                                                        alt={currentPost &&
                                                        currentPost.title}
                                                        className="post-img"
                                                    />
                                                </div>
                                                <div
                                                    className="news-detail-text"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            currentPost &&
                                                            currentPost.body
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                        <FooterBar />
                                    </div>
                                </div>
                                <RightBar />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentPost: state.news.currentPost
    };
};
const mapDispatchToProps = dispatch => {
    return {
        pullSinglePost: slug => dispatch(pullSinglePost(slug))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SingleNews)
);
