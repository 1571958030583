import React from "react";
import "./modal.css";

const modal = props => {
    return (
        <div className={props.show ? "Backdrop" : ""}>
            <div
                className="Modal"
                style={{
                    transform: props.show
                        ? "translateY(0)"
                        : "translateY(-100vh)",
                    opacity: props.show ? 1 : 0
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

export default modal;
