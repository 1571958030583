const newsReducerDefaultState = {
    news: [],
    guides: [],
    total: 0,
    newsData: {
        last_page: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
        per_page: null,
        current_page: null
    },
    guidesData: {
        last_page: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
        per_page: null
    },
    currentPost: null
};

export default (state = newsReducerDefaultState, action) => {
    switch (action.type) {
        case "GET_NEWS_DATA":
            const res = action.payload.data.posts;
            // console.log(res.total, res.data);

            return {
                ...state,
                news: res.data,
                newsData: {
                    total: res.total,
                    last_page: res.last_page,
                    next_page_url: res.next_page_url,
                    prev_page_url: res.prev_page_url,
                    path: res.path,
                    per_page: res.per_page,
                    current_page: res.current_page
                }
            };
        case "GET_GUIDE_DATA":
            const resx = action.payload.data.posts;
            // console.log('BUrada guide data!!!', resx.total, resx.data);

            return {
                ...state,
                guides: resx.data,
                guidesData: {
                    total: resx.total,
                    last_page: resx.last_page,
                    next_page_url: resx.next_page_url,
                    prev_page_url: resx.prev_page_url,
                    path: resx.path,
                    per_page: resx.per_page,
                    current_page: resx.current_page
                }
            };


            case "GET_SINGLE_POST":
            const singlePostData = action.payload.data;

            return {
                ...state,
                currentPost: singlePostData,
            };
        default:
            return state;
    }
};
