import React from "react";
import { connect } from "react-redux";
import P1 from "../../styles/img/p-1.png";
import AskYourGuideModal from "./AskYourGuideModal";
import { pullGuidesData } from "../../actions/news";


class YourGuide extends React.Component {
    componentDidMount() {
        this.props.pullGuidesData();
    }


    render() {
        const { news } = this.props;
        // console.log(news);
        // const base_url = "http://127.0.0.1:8000/storage/";
        const base_url = "https://exam.indiviq.online/storage/";
        return (
            <div className="content-block-wrap">
                <h3 className="tertiary-title">
                    Your Guide
                </h3>
                <div className="content-block">
                    <div className="user-guide-wrap">
                    {news.guides
                            ? news.guides.map((singleNews, singleNews_key) => {
                                  let d = new Date(
                                      Date.parse(singleNews.updated_at)
                                  );
                                  let dateString =
                                      ("0" + d.getDate()).slice(-2) +
                                      "-" +
                                      ("0" + (d.getMonth() + 1)).slice(-2) +
                                      "-" +
                                      d.getFullYear();
                                  return (
                                      <React.Fragment key={singleNews_key}>
                                          <div className="single-guide in-sidebar">
                                              <div className="guide-img">
                                                  <img
                                                      src={
                                                          base_url +
                                                          singleNews.picture
                                                      }
                                                      alt=""
                                                  />
                                              </div>
                                              <div className="gude-content">
                                                  <a href={'rehber/' + singleNews.slug}>
                                                      {singleNews.title}
                                                  </a>
                                                  <p>{dateString}</p>
                                              </div>
                                          </div>
                                      </React.Fragment>
                                  );
                              })
                            : "Makale yok"}

                        <div className="middle-btn text-center">
                            <a href="#" className="box-btn style-2" id="msg">
                                {/* Rehberinize Sorun */}
                                Ask Your Guide
                            </a>
                        </div>
                    </div>
                </div>
                <AskYourGuideModal />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        news: state.news
    };
};
const mapDispatchToProps = dispatch => {
    return {
        pullGuidesData: () => dispatch(pullGuidesData())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(YourGuide);
