import {login} from '../services/api'
import {SET_LOGIN_PENDING,SET_LOGIN_SUCCESS} from './actions';

export const attemptLogin =   () => {
    return  (dispatch) => {
        login.then(function(data){

            dispatch( setLoginSuccess());
        }).catch(
            function(err){
                dispatch( setLoginPending());

                console.info("in tbe error block")
            }
        )
    };
};



export const setLoginSuccess = () => {
    return {
        type:SET_LOGIN_SUCCESS
    }
};

export const setLoginPending = () => {
    return {
        type:SET_LOGIN_PENDING
    }
};
