import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import configureStore from "./reducers/configureStore";
import Index from "./components/dashboard/Index";
import Login from "./components/Login";
import Contact from "./components/Contact";
import Exam from "./components/Exam/Exam";
import PrintedExam from "./components/Exam/PrintedExam";
// import ExamEnd from "./components/dashboard/ExamEnd";
import News from "./components/News/News";
import Guides from "./components/Guides/Guides";
import SingleNews from "./components/News/SingleNews";
import SingleGuide from "./components/Guides/SingleGuide";
import Results from "./components/Results/Results";
const store = configureStore();
const state = store.getState();

const jsx = () => (
    <Provider store={store}>
        {
            <BrowserRouter>
                <Route path="/" component={Index} exact={true} />
                <Route path="/home" component={Index} exact={true} />
                <Route path="/login" component={Login} exact={true} />
                <Route path="/exam/:id" component={Exam} exact={true} />
                <Route path="/pexam/:id" component={PrintedExam} exact={true} />
                {/* <Route path="/exam_end/" component={ExamEnd} exact={true} /> */}
                <Route path="/haber/:slug" component={SingleNews} exact={true}/>
                <Route path="/haberler" component={News} exact={true} />
                <Route path="/rehber/:slug" component={SingleGuide} exact={true}/>
                <Route path="/rehber" component={Guides} exact={true} />
                <Route path="/results" component={Results} exact={true} />
                <Route path="/iletisim" component={Contact} exact={true} />
                {/* haberler = News */}
            </BrowserRouter>
        }
    </Provider>
);

export default jsx;
//ReactDOM.render(jsx, document.getElementById('app'));
