
const loginReducerDefaultState = {
    logged_in: false
};

export default (state = loginReducerDefaultState, action) => {
    switch (action.type) {
        case "SET_LOGIN_SUCCESS":
            console.log('User Logged in');
            return {
                logged_in: true
            };

        case "SET_LOGIN_PENDING":
            console.log('tUser not logged in');
            return {
                logged_in: false
            };

        default:
            return state;
    }
};
