//1- Sinav baslar ve tum bilgiler cekilir
//2- Sinav baslayinca timer start edilir, timer'a sure verilir
//3- Sure bitinceye kadar timer calisir
//4- Sure bitmeden emirle timer sonlandirilabilir
//5 - Sure bitince sure bitti der.

const timerReducerDefaultState = {
    timer_on: true
};

export default (state = timerReducerDefaultState, action) => {
    switch (action.type) {
        case "TIMER_START":
            console.log('timer is started');
            return {
                timer_on: true
            };

        case "TIMER_STOP":
            console.log('timer is stopped');
            return {
                timer_on: false
            };

        // case 'CHANGE_SHOW_QUESTION':

        //     return {...state, question_show: action.payload};

        default:
            return state;
    }
};
