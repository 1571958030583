import axios from "axios";
import { browserHistory } from "react-router";

// // var base_url = "http://localhost:9000/api/";
//   let base_url = "https://exam.indiviq.online/api/";
const base_url =
    process.env.NODE_ENV === "_development"
        ? "http://localhost:8003/api/"
        : "https://exam.indiviq.online/api/";

axios.interceptors.request.use(function(config) {
    config.headers.Authorization = "Bearer " + localStorage.auth_token;

    return config;
});

axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        // console.log(error.config.url);
        // console.log("eroor request", error.request);
        if (error.response) {
            if (
                !error.config.url.includes("login") &&
                error.request.status == 401 &&
                !window.location.href.includes("login")
            ) {
                console.log("error request");
                window.location = "/login";
            } else if (error.request.status == 401) {
                console.log("Not logged in");
                //  window.location = "/login";
            }
        }

        // Do something with response error
        return Promise.reject(error);
    }
);

export const login = (email, password) => {
    return new Promise((resolve, reject) => {
        let params = { email: email, password: password };

        axios
            .post(base_url + "login", params)
            .then(res => {
                const data = res.data;

                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const register = (name, email, password, c_password) => {
    return new Promise((resolve, reject) => {
        let params = { name, email, password, c_password };

        axios
            .post(base_url + "register", params)
            .then(res => {
                const data = res.data;

                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const logout = () => {
    return new Promise((resolve, reject) => {
        axios
            .post(base_url + "logout")
            .then(res => {
                const data = res.data;
                resolve(data);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });
};
export const userDetail = new Promise((resolve, reject) => {
    axios
        .post(base_url + "details")
        .then(res => {
            const data = res.data;

            resolve(data);
        })
        .catch(err => {
            reject(err);
        });
});

// export const userDetail = async () => {
//     const result = await axios.post(base_url + "details");
//     console.log(result);
//     return result;
// };

export const dashboardData = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(base_url + "dashboard")
            .then(res => {
                // console.log("dashboardData promise back", res, res.data);
                const data = res.data;

                resolve(data);
            })
            .catch(err => {
                console.log("dashboardData promise error", err);

                reject(err);
            });
    });
};

export const upcomingExams = (date = null) => {
    return new Promise((resolve, reject) => {
        axios
            .get(base_url + "upcoming-exams?date=" + date)
            .then(res => {
                const data = res.data;

                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const examStartByCode = code => {
    return new Promise((resolve, reject) => {
        let params = { exam_code: code };
        axios
            .post(base_url + "exam/start", params)
            .then(res => {
                const data = res.data;
                // console.log("data", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};
export const examCheckByCode = code => {
    return new Promise((resolve, reject) => {
        let params = { exam_code: code };
        axios
            .post(base_url + "exam/check", params)
            .then(res => {
                const data = res;
                // console.log("data", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const examStartByID = id => {
    return new Promise((resolve, reject) => {
        let params = { exam_id: id };
        axios
            .post(base_url + "exam/start", params)
            .then(res => {
                const data = res.data;
                // console.log('examStart api data', data, res.status );
                if (data.status === "error") {
                }
                resolve(data);
            })
            .catch(err => {
                console.log("Error geldi");
                console.log("examStart error data", err.response);
                reject(err);
            });
    });
};
export const getExamDetailsById = id => {
    return new Promise((resolve, reject) => {
        // let params = { exam_id: id };
        axios
            .post(base_url + "exam/" + id)
            .then(res => {
                const data = res.data;
                 console.log('getExamDetailsById api data', data, res.status );
                if (data.status === "error") {
                }
                resolve(data);
            })
            .catch(err => {
                console.log("Error geldi");
                console.log("getExamDetailsById error data", err.response);
                reject(err);
            });
    });
};
export const getNews = (pagination = 5, pageUrl = null, category = 0) => {
    //category 0 news, 1 guides
    return new Promise((resolve, reject) => {
        axios
            .get(
                (pageUrl ? pageUrl + "&" : base_url + "posts?") +
                    "pagination=" +
                    pagination +
                    "&category=" +
                    category
            )
            .then(res => {
                const data = res.data;
                // console.log("news data", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const getGuides = (pagination = 5, pageUrl = null, category = 1) => {
    //category 0 news, 1 guides
    return new Promise((resolve, reject) => {
        axios
            .get(
                (pageUrl ? pageUrl + "&" : base_url + "posts?") +
                    "pagination=" +
                    pagination +
                    "&category=" +
                    category
            )
            .then(res => {
                const data = res.data;
                // console.log("guides data", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const examFinish = (exam_id, session_id, answers_id) => {
    return new Promise((resolve, reject) => {
        // let answers_id= {1:2,3:9}
        // a[2]=4;
        // a[4]=8;
        // a[9]=99;
        // console.log(exam_id, answers_id);
        let params = {
            answers_id,
            exam_id: exam_id,
            session_id
        };
        axios
            .post(base_url + "exam/finish", params)
            .then(res => {
                const data = res.data;

                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const saveAnswers = (exam_id, session_id, answers_id) => {
    return new Promise((resolve, reject) => {
        // console.log(exam_id, answers_id);
        let params = {
            answers_id,
            exam_id: exam_id,
            session_id
        };
        axios
            .post(base_url + "exam/save-answers", params)
            .then(res => {
                const data = res.data;

                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const getResultListService = () => {
    //category 0 news, 1 guides
    return new Promise((resolve, reject) => {
        axios
            .get(base_url + "result")
            .then(res => {
                const data = res.data;
                // console.log("news data", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const getResultDetailService = session_id => {
    //category 0 news, 1 guides
    return new Promise((resolve, reject) => {
        axios
            .get(base_url + "result/detail?session_id=" + session_id)
            .then(res => {
                const data = res.data;
                // console.log("news data", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const getPostBySlug = slug => {
    return new Promise((resolve, reject) => {
        axios
            .get(base_url + "post-slug/" + slug)
            .then(res => {
                const data = res.data;
                console.log("post byt slug data", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};


