import React from "react";
import { connect } from "react-redux";
import BadgeYellow from "../../styles/img/badge-2-yellow.svg";
// import BadgeYellow from "../../styles/img/badge-2.svg";
import LeftAngle from "../../styles/img/left-angle.svg";
import RightAngle from "../../styles/img/right-angle.svg";
import {
    changeShowQuestion,
    changeSearchCourseText,
    changeLeftBarFilter
} from "../../actions/exam";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { FILTER_OPTIONS } from "../../services/constants";
import { withRouter } from "react-router-dom";

class LeftOptionBar extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    selectQuestion = e => {
        this.props.dispatch(changeShowQuestion(parseInt(e.target.value)));
        setTimeout(() => {
            const currentEl = document.getElementsByClassName(
                "single-mcq-row active"
            )[0];
            document.querySelector(
                ".simplebar-content-wrapper"
            ).scrollTop = currentEl
                ? currentEl.offsetTop
                : document.querySelector(".simplebar-content-wrapper")
                      .scrollTop;
        }, 500);
    };
    gotoQuestion = key => {
        this.props.dispatch(changeShowQuestion(key));
    };
    changeFilter = direction => {
        this.props.dispatch(changeLeftBarFilter(direction)); //left-right
    };
    render() {
        const onResultPage =
            this.props.history.location.pathname === "/results";
        return (
            <div className="mcq-wrap">
                <div className="mcq-header">
                    {!onResultPage ? (
                        <React.Fragment>
                            <span
                                className="filter-arrow filter-left-arrow"
                                onClick={this.changeFilter.bind(this, "left")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="14"
                                    viewBox="0 0 8 14"
                                    fill="none"
                                >
                                    <path
                                        d="M8 1.645L6.47773 0L0 7L6.47773 14L8 12.355L3.05533 7L8 1.645Z"
                                        fill="#888999"
                                    />
                                </svg>
                            </span>
                            <p
                                className={
                                    this.props.leftBarFilter !== "ALL"
                                        ? "left-bar-filtered"
                                        : ""
                                }
                            >
                                {" "}
                                {FILTER_OPTIONS[this.props.leftBarFilter]}{" "}
                                <span> {this.props.questions.length} </span>
                            </p>
                            <span
                                className="filter-arrow filter-right-arrow"
                                onClick={this.changeFilter.bind(this, "right")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="14"
                                    viewBox="0 0 8 14"
                                    fill="none"
                                >
                                    <path
                                        d="M1.4381e-07 12.355L1.52227 14L8 7L1.52227 -5.66301e-07L1.08011e-06 1.645L4.94467 7L1.4381e-07 12.355Z"
                                        fill="#888999"
                                    />
                                </svg>
                            </span>
                        </React.Fragment>
                    ) : (
                        <div className="result-left-option-bar-title">
                            Cevaplarınız
                        </div>
                    )}
                </div>
                <div className="mcq-content-wrap">
                    {/* <SimpleBar className="simple-bar-left"> */}
                    {this.props.questions.length > 0 ? (
                        <SimpleBar className="simple-bar-left" id="leftt">
                            <div className="mcq-option-list-area">
                                <div className="mcq-option-list">
                                    {this.props.questions.map(
                                        (question, key) => {
                                            return (
                                                <div
                                                    className={
                                                        key + 1 ===
                                                        this.props.question_show
                                                            ? "single-mcq-row active"
                                                            : "single-mcq-row"
                                                    }
                                                    onClick={this.gotoQuestion.bind(
                                                        this,
                                                        key + 1
                                                    )}
                                                    key={key}
                                                    ref={this.myRef}
                                                >
                                                    {question.favorited && (
                                                        <img
                                                            src={BadgeYellow}
                                                            alt=""
                                                            className="trans-badge"
                                                        />
                                                    )}
                                                    <span className="sl-no">
                                                        {key + 1}.
                                                    </span>
                                                    <span className="mcq-option">
                                                        {question.selectedAnswer
                                                            ? question.selectedAnswer
                                                            : "-"}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </SimpleBar>
                    ) : (
                        <span className="filter-null-text">
                            {FILTER_OPTIONS[this.props.leftBarFilter]} soru yok.
                        </span>
                    )}
                </div>

                {this.props.questions.length > 0 && (
                    <div className="mcq-footer">
                        <label htmlFor="go-question">Soruya Git</label>
                        <select
                            id="go-question"
                            onChange={this.selectQuestion}
                            value={this.props.question_show}
                        >
                            {this.props.questions.length > 0
                                ? this.props.questions.map((question, key) => {
                                      return (
                                          <option value={key + 1} key={key}>
                                              {key + 1}
                                          </option>
                                      );
                                  })
                                : ""}
                        </select>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        question_show,
        leftBarFilter,
        searchCourseText,
        questions,
        course_representation_type //0 Represent as course , 1 - Show as group, 2 Both
    } = state.exam;
    // const questionsToShowAfterCourseFilters =
    //     state.exam.searchCourseText === ""
    //         ? state.exam.questions
    //         : state.exam.questions.filter(
    //               question => question.course_id == state.exam.searchCourseText
    //           );
    let questionsToShowAfterCourseFilters;
    if (course_representation_type === 1) {
        questionsToShowAfterCourseFilters =
            searchCourseText === ""
                ? questions
                : questions.filter(
                      question => question.course_group_id == searchCourseText
                  );
    } else {
        questionsToShowAfterCourseFilters =
            searchCourseText === ""
                ? questions
                : questions.filter(
                      question => question.course_id == searchCourseText
                  );
    }
    const questionsToShowAfterLeftFilters =
        leftBarFilter === "ALL"
            ? questionsToShowAfterCourseFilters
            : questionsToShowAfterCourseFilters.filter(question => {
                  switch (leftBarFilter) {
                      case "FAVORITE":
                          return question.favorited;
                          break;
                      case "EMPTY":
                          return question.answer === null;
                          break;
                      case "FULL":
                          return question.answer !== null;
                          break;
                      default:
                          break;
                  }
              });
    return {
        question_show,
        leftBarFilter,
        questions: questionsToShowAfterLeftFilters
    };
};
export default withRouter(connect(mapStateToProps)(LeftOptionBar));
