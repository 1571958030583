import {GET_RESULT_LIST, GET_RESULT_DETAIL, CHANGE_TO_RESULT_VIEW_LIST} from "./actions";
import {spinner} from "./spinner";
import {getResultDetailService, getResultListService} from "../services/api";
import {startExam} from "./exam";

export const pullResultList = () => {
    return (dispatch) => {
        dispatch(spinner(true));
        getResultListService().then(function (data) {
            dispatch(getResultList(data));
            dispatch(spinner(false));
        }).catch(
            function (err) {
                dispatch(spinner(false));
                console.info(" news error", err)
            }
        ).finally(() => {
        })
    };
};

export const pullResultDetail = (session_id) => {
    return (dispatch) => {
        dispatch(spinner(true));
        getResultDetailService(session_id).then(function (data) {
            dispatch(getResultDetail(data));
            dispatch(startExam(data.data));
            dispatch(spinner(false));
        }).catch(
            function (err) {
                dispatch(spinner(false));
                console.info(" news error", err)
            }
        ).finally(() => {
        })
    };
};

export const getResultList = (data) => {
    return {
        type: GET_RESULT_LIST,
        payload: data
    }
};
export const getResultDetail = (data) => {
    return {
        type: GET_RESULT_DETAIL,
        payload: data
    }
};

export const changeToResultViewList = () => {
    return {
        type: CHANGE_TO_RESULT_VIEW_LIST
    }
};
