import React from "react";
import { connect } from "react-redux";
import { spinner } from "../actions/spinner";
import Logo from "../styles/img/logo.png";
import Bell from "../styles/img/bell.svg";
import User from "../styles/img/user.png";
import User2 from "../styles/img/user-2.png";
import { withRouter } from "react-router-dom";
import {setShowIntroductionData } from "../actions/dashboard";

import {
    changeToResultViewList
} from "../actions/result";
class LeftBar extends React.Component {
    // componentDidMount() {
    //     console.log("left bar", this.props);
    // }
    goToHome = () => {
        console.log('hommee')
        this.props.showingIntroduction && this.props.setShowIntroductionData({
            showingIntroduction:null,
            examIdForIntroduction:null,
            examCodeForIntroduction:null
        });

        this.props.history.push(`/home`);
    };
    goToNews = () => {
        this.props.history.push(`/haberler`);
    };
    goToGuides = () => {
        this.props.history.push(`/rehber`);
    };

    goToResults = () => {
        this.props.changeToResultViewList();

        this.props.history.push(`/results`);
        console.log('here on change view')
    };
    render() {
        const { pathname } = this.props.history.location;
        // console.log("pathname", pathname, this.props.history);
        return (
            <div className="sidebar">
                <div className="sidebar-inner">
                    <ul className="sidebar-menu">
                        <li className={pathname === "/home" ? "active" : ""}>
                            <a onClick={this.goToHome}>
                                <span className="menu-icon">
                                    <svg
                                        width="20"
                                        height="18"
                                        viewBox="0 0 20 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3 9.5H0L10 0.5L20 9.5H17V17.5H11V11.5H9V17.5H3V9.5ZM15 7.69L10 3.19L5 7.69V15.5H7V9.5H13V15.5H15V7.69Z"
                                            fill="#333555"
                                        />
                                    </svg>
                                </span>
                                <span className="hover-text">Anasayfa</span>
                            </a>
                        </li>
                        <li className={pathname === "/results" ? "active" : ""}>
                            <a onClick={this.goToResults}>
                                <span className="menu-icon">
                                    <svg
                                        width="22"
                                        height="18"
                                        viewBox="0 0 22 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.5 9C3.5 4.03 7.53 0 12.5 0C17.47 0 21.5 4.03 21.5 9C21.5 13.97 17.47 18 12.5 18C10.01 18 7.77 16.99 6.14 15.36L7.56 13.94C8.82 15.21 10.57 16 12.5 16C16.37 16 19.5 12.87 19.5 9C19.5 5.13 16.37 2 12.5 2C8.63 2 5.5 5.13 5.5 9H8.5L4.5 12.99L0.5 9H3.5ZM11.5 10V5H13V9.15L16.52 11.24L15.75 12.52L11.5 10Z"
                                            fill="#21CF9A"
                                        />
                                    </svg>
                                </span>
                                <span className="hover-text">Sonuçlar</span>
                            </a>
                        </li>
                        <li className={pathname === "/haberler" ? "active" : ""}>
                            <a onClick={this.goToNews}>
                                <span className="menu-icon">
                                    <svg
                                        width="22"
                                        height="18"
                                        viewBox="0 0 22 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2 0.5H20C21.1 0.5 22 1.4 22 2.5V15.5C22 16.6 21.1 17.5 20 17.5H2C0.9 17.5 0 16.6 0 15.5V2.5C0 1.4 0.9 0.5 2 0.5ZM2 2.5V15.5H10V2.5H2ZM20 15.5H12V2.5H20V15.5ZM19 6H13V7.5H19V6ZM13 8.5H19V10H13V8.5ZM19 11H13V12.5H19V11Z"
                                            fill="#888999"
                                        />
                                    </svg>
                                </span>
                                <span className="hover-text">Haberler</span>
                            </a>
                        </li>
                        <li>
                            <a onClick={this.goToGuides}>
                                <span className="menu-icon">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 2C21 0.895431 20.1046 0 19 0H7C5.89543 0 5 0.895431 5 2V3H7V2H19V10H18V12H19C20.1046 12 21 11.1046 21 10V2Z" fill="#888999"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2 4C0.895431 4 0 4.89543 0 6V16V20L4.5 16H15C16.1046 16 17 15.1046 17 14V6C17 4.89543 16.1046 4 15 4H2ZM15 6H2V15.5L3.75 14H15V6ZM4.5 9C4.5 8.72386 4.72386 8.5 5 8.5H12C12.2761 8.5 12.5 8.72386 12.5 9C12.5 9.27614 12.2761 9.5 12 9.5H5C4.72386 9.5 4.5 9.27614 4.5 9ZM4.5 11C4.5 10.7239 4.72386 10.5 5 10.5H9C9.27614 10.5 9.5 10.7239 9.5 11C9.5 11.2761 9.27614 11.5 9 11.5H5C4.72386 11.5 4.5 11.2761 4.5 11Z" fill="#888999"/>
                                </svg>
                                </span>
                                <span className="hover-text">
                                   Rehberiniz
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        showingIntroduction: state.dashboard.showingIntroduction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShowIntroductionData: data => dispatch(setShowIntroductionData(data)),
        changeToResultViewList:()=>dispatch(changeToResultViewList())
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftBar));

