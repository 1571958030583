import React from 'react';
import {connect} from 'react-redux';
import QuestionMark from "../../styles/img/question-mark.svg";
import Alarm from "../../styles/img/alarm.svg";
import {upcomingExams} from "../../services/api";

class UpcomingExams extends React.Component {

    render() {
        return (
            <div className="content-block-wrap">
                <h3 className="tertiary-title">Upcoming Exams </h3>
                <div className="content-block">
                    <div className="table-scroll-wrap">
                        <table className="table exam-table">
                            <tbody>
                            {this.props.upcomingExams.length ? this.props.upcomingExams.map((upcomingExam)=>{
                               return <tr key={upcomingExam.id}>
                                   <td>
                                       <div className="active-exam-wrap">
                                           <h4 className="exam-title">{upcomingExam.name}</h4>
                                           <p><span><img src={QuestionMark}
                                                         alt=""/> {upcomingExam.questions_count} Soru</span> <span><img
                                               src={Alarm} alt=""/>  {upcomingExam.exam_time} dk</span>
                                           </p>
                                       </div>
                                   </td>
                                   <td>
                                       {/*<button className="box-btn start">Start</button>*/}
                                   </td>
                               </tr>
                            }) :  <tr>
                                        <td
                                        // style={{borderBottom: 0}}
                                        >
                                            <div className="active-exam-wrap" style={{textAlign:'left'}}>
                                                {/* <p>Yaklaşan Sınav bulunmamaktadır.</p> */}
                                                <p>There is no upcoming exam.</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        upcomingExams:state.dashboard.upComingExams.data
    };
};


export default connect(mapStateToProps)(UpcomingExams);
