import { ANS_OPTIONS, FILTER_OPTIONS } from "../services/constants";

const examReducerDefaultState = {
    questions: [],
    question_show: null,
    course_representation_type:null,
    courses: [],
    course_groups:[],
    exam_state: "not_started",
    selected_answers: {},
    exam_id: null,
    exam_time: null,
    exam_type_name:null,
    evaluation_type_name:null,
    evaluation_type:null,
    exam_finish_time: null,
    filter: "all",
    exam_start_time: null,
    name: "",
    answer_status: "",
    ashowstart_time: "",
    afinish_time: "",
    public: "",
    status: "",
    // questionsData: [],
    searchCourseText: "",
    session_id: "",
    temp_ques_ans: [],
    exam_enter_status: undefined,
    result: false,
    leftBarFilter: "ALL"
};

export default (state = examReducerDefaultState, action) => {
    switch (action.type) {
        case "EXAM_START":
            console.log("__________-EXAM_START____________", action.payload);
            const Questions = action.payload.questions;
            const exam_courses = action.payload.exam_courses;
            const ExamCourses = exam_courses.map(
                e_course => e_course.course_id
            );

            const newQ = {};
            const lessons = [];

            // console.log("Questions", Questions);
            Questions.map(qItem => {
                // HERE We add new properties to the question objects
                qItem.favorited = false;
                qItem.answer = null;
                qItem.selectedAnswer = null;
                qItem.timeSpent = null;
                qItem.notes = null;
                qItem.questionId = null;
                qItem.isTrue = null;
                if (newQ[qItem.course_id]) {
                    // Here we add these questions to the newQ under course name to seperate the questions.
                    newQ[qItem.course_id].push(qItem);
                } else {
                    lessons.push(qItem.course_id);
                    newQ[qItem.course_id] = [];
                    newQ[qItem.course_id].push(qItem);
                }
            });

            ///////////////////////////////////////////////////////////////////////
            // Checking if the courses from exam-course elements are valid - checking with courses coming from questions
            // TODO This should be removed after exam creation UI finalized.
            // if (vendors.filter(e => e.Name === 'Magenic').length > 0) {
            //     /* vendors contains the element we're looking for */
            //   }
            let checkedValidCourses = exam_courses.filter(x => {
                // console.log(x);
                return lessons.includes(x.course_id);
            });
            // console.log("checkedValidCourses", checkedValidCourses);
            checkedValidCourses.length < lessons.length &&
                console.log(
                    "⚠️ WARNING!!!! THE EXAM_COURSES COMING FROM SERVER IS NOT CONTAINING ALL THE COURSE IDS!!!! "
                );
            ////////////////////////////////////////////////////////////////////////

            let NewQuestions = [];
            if (!action.payload.result) {
                // for (let [key, value] of Object.entries(newQ)) {
                //     console.log(key, value, 'keyvalue')
                //     value.sort(randomize);
                // }

                // console.log("lessons", lessons);
                // console.log("ExamCourses", ExamCourses);
                // console.log("Courses", action.payload.courses);
                // console.log("Course Groups", action.payload.course_groups);

                for (let i = 0; i < checkedValidCourses.length; i++) {
                    // Here we randomize questions under courses - Only under the course


                    const value = checkedValidCourses[i].qshow_type
                        ? newQ[checkedValidCourses[i].course_id].sort(randomize)
                        : newQ[checkedValidCourses[i].course_id];
                    NewQuestions.push(...value);
                }
                function randomize(a, b) {
                    // console.log('randomized')
                    return Math.random() - 0.5;
                }

                if (
                    action.payload.session_data.student_exam_answers &&
                    action.payload.session_data.student_exam_answers !=
                        undefined
                ) {
                    // Checking previous answers from the exam
                    let selected_ans =
                        action.payload.session_data.student_exam_answers;
                    // console.log("selected_ans", selected_ans);
                    for (let [key, value] of Object.entries(NewQuestions)) {
                        if (
                            selected_ans[value.id] != undefined &&
                            selected_ans[value.id] != null
                        ) {
                            for (
                                let l = 0;
                                l < value.answers_in_random_order.length;
                                l++
                            ) {
                                // console.log(
                                //     value.answers_in_random_order[l],
                                //     selected_ans[value.id]
                                // );
                                if (
                                    value.answers_in_random_order[l].id ==
                                    selected_ans[value.id]
                                ) {
                                    value.answer =
                                        value.answers_in_random_order[l].id;
                                    value.selectedAnswer = ANS_OPTIONS[l];
                                    value.isTrue =
                                        value.answers_in_random_order[l].true;
                                }
                            }
                        }
                    }
                }
            } else {
                NewQuestions = Questions;

                if (
                    action.payload.session_data.student_exam_answers &&
                    action.payload.session_data.student_exam_answers !=
                        undefined
                ) {
                    let selected_ans =
                        action.payload.session_data.student_exam_answers;
                    // console.log(selected_ans);
                    for (let [key, value] of Object.entries(NewQuestions)) {
                        if (
                            selected_ans[value.id] != undefined &&
                            selected_ans[value.id] != null
                        ) {
                            for (let l = 0; l < value.answers.length; l++) {
                                // console.log(
                                //     value.answers[l],
                                //     selected_ans[value.id]
                                // );
                                if (
                                    value.answers[l].id ==
                                    selected_ans[value.id]
                                ) {
                                    value.answer = value.answers[l].id;
                                    value.selectedAnswer = ANS_OPTIONS[l];
                                    value.isTrue = value.answers[l].true;
                                }
                            }
                        }
                    }
                }
            }
            // console.log(
            //     "**********",
            //     action.payload.session_data.student_exam_answers
            // );

            // if (
            //     action.payload.session_data.student_exam_answers &&
            //     action.payload.session_data.student_exam_answers != undefined
            // ) {
            //     let selected_ans =
            //         action.payload.session_data.student_exam_answers;
            //     console.log(selected_ans);
            //     for (let [key, value] of Object.entries(NewQuestions)) {
            //         if (
            //             selected_ans[value.id] != undefined &&
            //             selected_ans[value.id] != null
            //         ) {
            //             for (
            //                 let l = 0;
            //                 l < value.answers_in_random_order.length;
            //                 l++
            //             ) {
            //                 console.log(
            //                     value.answers_in_random_order[l],
            //                     selected_ans[value.id]
            //                 );
            //                 if (
            //                     value.answers_in_random_order[l].id ==
            //                     selected_ans[value.id]
            //                 ) {
            //                     value.answer =
            //                         value.answers_in_random_order[l].id;
            //                     value.selectedAnswer = ANS_OPTIONS[l];
            //                     value.isTrue =
            //                         value.answers_in_random_order[l].true;
            //                 }
            //             }
            //         }
            //     }
            // }

            let show_answer = false;
            if (action.payload.answer_status == 1) {
                show_answer = true;
            } else if (action.payload.answer_status == 2) {
                let now = Date.parse(new Date());
                // if(action.payload.ashowstart_time == null){
                //
                // }
                let afinish_time = action.payload.afinish_time
                    ? Date.parse(action.payload.afinish_time)
                    : null;
                let ashowstart_time = Date.parse(
                    action.payload.ashowstart_time
                );
                // console.log("ANSWERRRRR", now, afinish_time);
                // debugger;
                if (
                    ashowstart_time <= now &&
                    (afinish_time > now || afinish_time == null)
                ) {
                    show_answer = true;
                }
            }

            // console.log(
            //     "____action.payload.:",
            //     action.payload.session_data.id,
            //     action.payload.session_data.exam_enter_status
            // );
            return {
                exam_id: action.payload.id,
                questions: NewQuestions,
                question_show: 1,
                course_representation_type:action.payload.course_representation_type,
                course_groups:action.payload.course_groups,
                courses: action.payload.courses,
                exam_state:
                    action.payload.id === state.exam_id
                        ? state.exam_state
                        : "started",
                selected_answers: {},
                exam_time: action.payload.exam_time,
                filter: "all",
                exam_start_time: Date.now(), //We are setting the exam start time here.
                // questionsData: QuestionsData,
                exam_finish_time: null,
                exam_type_name: action.payload.exam_type_name,
                evaluation_type: action.payload.evaluation_type,
                evaluation_type_name: action.payload.evaluation_type_name,
                searchCourseText: "",
                name: action.payload.name,
                answer_status: action.payload.answer_status,
                show_answer: show_answer,
                ashowstart_time: action.payload.ashowstart_time,
                afinish_time: action.payload.afinish_time,
                public: action.payload.public,
                status: action.payload.status,
                instruction: action.payload.instruction,
                session_id: action.payload.session_data.id,
                temp_ques_ans: [],
                exam_enter_status:
                    action.payload.session_data.exam_enter_status, // According to exam status with exam session
                result: action.payload.result,
                leftBarFilter: "ALL"
            };

        case "EXAM_SUBMIT":
            return {
                ...state,
                exam_finish_time: Date.now(), //We are setting the exam finish time here.
                exam_state: "finished"
            };

        case "QUESTION_SELECT":
             console.log("payload QUESTION_SELECT", action.payload);
             //questionIndex, answerKey, isTrue, answerId
             //data, answer, isTrue, answerId
            const qIndex = action.payload[0];
            const qAnswer = action.payload[1];
            const isTrueAnswer = action.payload[2];
            const answerId = action.payload[3];
            return {
                ...state,
                questions: [
                    ...state.questions.slice(0, qIndex),
                    {
                        ...state.questions[qIndex],
                        selectedAnswer: qAnswer,
                        isTrue: isTrueAnswer,
                        answer: answerId
                    },
                    ...state.questions.slice(1 + qIndex)
                ],
                temp_ques_ans: [...state.temp_ques_ans, qIndex]
                // questionsData: [
                //     ...state.questionsData.slice(0, qIndex),
                //     {
                //         ...state.questionsData[qIndex],
                //         selectedAnswer: qAnswer,
                //         isTrue: isTrueAnswer
                //     },
                //     ...state.questionsData.slice(1 + qIndex)
                // ]
            };

        case "CHANGE_SEARCH_COURSE_TEXT":
            return {
                ...state,
                searchCourseText: action.payload,
                question_show: 1
            };

        case "CHANGE_LEFT_BAR_FILTER":
            const filterOptionKeys = Object.keys(FILTER_OPTIONS).sort();
            // console.log('FILTER_OPTIONS', filterOptionKeys)
            const currentIndex = filterOptionKeys.indexOf(state.leftBarFilter);
            const difference = action.payload === "right" ? 1 : -1;
            const nextIndex =
                (currentIndex + difference) % filterOptionKeys.length;
            const newLeftBarFilter = filterOptionKeys[nextIndex];
            return {
                ...state,
                leftBarFilter: newLeftBarFilter,
                question_show: 1
            };

        case "CHANGE_SHOW_QUESTION":
            const newQuestionNumber = action.payload.data;
            if (
                newQuestionNumber >= state.questions.length ||
                newQuestionNumber <= 1
            ) {
                return state.question_show;
            }
            return { ...state, question_show: action.payload };

        case "QUESTION_FAVORITE":
            // console.log("payload QUESTION_FAVORITE", action.payload);
            const index = action.payload[0];
            const status = action.payload[1];
            return {
                ...state,

                questions: [
                    ...state.questions.slice(0, index),
                    { ...state.questions[index], favorited: status },
                    ...state.questions.slice(1 + index)
                ]
            };

        case "SAVE_ANSWERS":
            return {
                ...state,
                temp_ques_ans: []
            };
        default:
            return state;
    }
};
