import React from "react";
import { connect } from "react-redux";
import Header from "../Header";
import LeftBar from "../LeftBar";
// import YourGuide from "../dashboard/YourGuide";
import { spinner } from "../../actions/spinner";
import { pullGuidesData } from "../../actions/news";
import Spinner from "../Spinner";
import ExamStartByCode from "../dashboard/ExamStartByCode";
import { NotificationContainer } from "react-notifications";
import FooterBar from "../FooterBar";
import RightBar from "./../dashboard/RightBar";
import SingleNews from "../News/SingleNews";

class Guides extends React.Component {

    state = {
        name: ""
    };

    componentDidMount() {
        this.props.pullGuidesData();
        window.scrollTo(0, 0);

    }

    render() {
        // console.log(this.props)
        const { news } = this.props;
        // console.log(news);
        // const base_url = "http://127.0.0.1:8000/storage/";
        const base_url = "https://exam.indiviq.online/storage/";
        // const SingleNew = () => {
        //     // const {news} = this.props;
        //     // news?news:[];
        //     // const listItems = news.news ? news.news.map(oneNews => <li>{oneNews.title}</li>) : 'No news';
        //     // return <ul>{listItems}</ul>;
        // };
        return (
            <div className="all-news-page">
                <NotificationContainer />

                <Header />
                <Spinner />
                <div className="outer-all-wrap">
                    <LeftBar />
                    <main >
                        <div className="main-content-wrapper" >
                            <div className="row no-margin" >
                                <div className="col-lg-9 no-padding" >
                                    <div className="main-left-wrapper">
                                        <h3 className="tertiary-title">
                                            Rehberiniz
                                        </h3>
                                        <div className="content-block">
                                            <div className="user-guide-wrap">
                                                {news.guides
                                                    ? news.guides.map(
                                                          singleNews => {
                                                              return (
                                                                  <div className="single-guide bdr-bottom" key={singleNews.id}>
                                                                      <div className="guide-img">
                                                                          <img
                                                                              src={base_url + singleNews.picture}
                                                                              alt=""
                                                                          />
                                                                      </div>
                                                                      <div className="gude-content">
                                                                          <a
                                                                              href={
                                                                                  "haber/" +
                                                                                  singleNews.slug
                                                                              }
                                                                          >
                                                                              {
                                                                                  singleNews.title
                                                                              }
                                                                          </a>
                                                                          <p>
                                                                              12.12.2019
                                                                          </p>
                                                                      </div>
                                                                  </div>
                                                              );
                                                          }
                                                      )
                                                    : "Haber yok"}
                                            </div>
                                        </div>
                                        <FooterBar/>

                                    </div>

                                </div>
                                <RightBar />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        news: state.news
    };
};

const mapDispatchToProps = dispatch => {
    return {
        pullGuidesData: () => dispatch(pullGuidesData())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guides);
