import React from 'react';
import {connect} from 'react-redux';
class AskYourGuideModal extends React.Component {

    render() {
        {/*cModal-open*/}
        return (
            <div className="c-modal-outer-wrap" id="c-modal-one">
                <div className="c-modal-inner-wrap">
                    <span className="cls-modal"><i className="fal fa-times"></i></span>
                    <div className="c-modal-body">
                        <div className="msg-popup-content">
                            <form action="#">
                                <h4>REHBERE SOR</h4>
                                <p>Rehberinize sormak istediğiniz konuyu detaylı bir şekilde yazın. En kısa sürede
                                    geri dönüş sağlanacaktır.</p>
                                <div className="exam-code-left">
                                    <label htmlFor="">mesajınız</label>
                                    <textarea name="" id="" cols="30" rows="10"></textarea>
                                </div>
                                <div className="text-center mt-30">
                                    <button className="box-btn style-2">Gönder</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};


export default connect(mapStateToProps)(AskYourGuideModal);
