import {
    EXAM_START,
    EXAM_SUBMIT,
    CHANGE_SHOW_QUESTION,
    CHANGE_SEARCH_COURSE_TEXT,
    QUESTION_FAVORITE,
    QUESTION_SELECT, SAVE_ANSWERS,
    CHANGE_LEFT_BAR_FILTER
} from "../actions/actions";
import {examFinish, login,saveAnswers} from "../services/api";
// import {setLoginPending, setLoginSuccess} from "./login";
import {createNotification} from "../services/utils";

export const submitExam = (exam_id, session_id,answers_id) => {
    return (dispatch) => {
        examFinish(exam_id,session_id, answers_id).then(function (data) {

            dispatch(finishExam(data));
        }).catch(
            function (err) {
                createNotification('error', 'Your Exam is not submitting please contact your Administration', 'Submit error');

                console.info("in tbe error block")
            }
        )
    };
};

export const storeAnswers = (exam_id, session_id,answers_id) => {
    return (dispatch) => {
        saveAnswers(exam_id, session_id,answers_id).then(function (data) {

            dispatch(sendSelectedAnswersToServer(data));
        }).catch(
            function (err) {
                createNotification('error', 'Backup of your selected answers is not storing. Please contact your administration.', 'Answer Save Error');

                console.info("in tbe error block")
            }
        )
    };
};

export const startExam = (data) => {
    return {
        type: EXAM_START,
        payload: data
    }
};

export const finishExam = (data) => {
    return {
        type: EXAM_SUBMIT,
        payload: data
    }
};

export const sendSelectedAnswersToServer = () => {
    return {
        type: SAVE_ANSWERS,
    }
};

export const changeSearchCourseText = (data) => {
    return {
        type: CHANGE_SEARCH_COURSE_TEXT,
        payload: data
    }
};

export const changeLeftBarFilter = (data) => {
    return {
        type: CHANGE_LEFT_BAR_FILTER,
        payload: data
    }
};

export const changeShowQuestion = (data) => {
    return {
        type: CHANGE_SHOW_QUESTION,
        payload: data
    }
};

export const favoriteQuestion = (data, status) => {
    return {
        type: QUESTION_FAVORITE,
        payload: [data, status]
    }
};
export const answerQuestion = (data, answer, isTrue, answerId) => {
    return {
        type: QUESTION_SELECT,
        payload: [data, answer, isTrue, answerId]
    }
};
