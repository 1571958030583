export const ANS_OPTIONS = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G"
];

export const FILTER_OPTIONS= {
    ALL: "All",
    FAVORITE: "Bookmarked",
    EMPTY: "Empty",
    FULL: "Answered",
};
