import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoginSuccess } from "../actions/login";
import Logo from "../styles/img/indiviexam2.png";
import Slider1 from "../styles/img/slider1.png";
import Slider2 from "../styles/img/slider2.png";
import { login, register } from "../services/api";
import Spinner from "./Spinner";
import { spinner } from "../actions/spinner";

// require('../styles/css/style.css');

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: "login", //allowed values login,register
            errors: {
                name: "",
                email: "",
                password: "",
                c_password: "",
                agreement: ""
            },
            error_message: "",
            success_message: ""
        };
    }

    changeView = view => {
        this.setState({
            view,
            errors: {
                name: "",
                email: "",
                password: "",
                c_password: "",
                agreement: ""
            },
            error_message: "",
            success_message: ""
        });
    };

    loginHandler = e => {
        e.preventDefault();
        this.props.spinner(true);

        let email = this.refs.login_email.value;
        let password = this.refs.login_password.value;
        // this.props.attemptLogin(email, password);
        // return;
        login(email, password)
            .then(response => {
                console.log(response);

                if (response.success.token) {
                    localStorage.auth_token = response.success.token;

                    this.setState({
                        error_message:
                            "Something went wrong please try again later"
                    });
                }
                console.log('logged in successfully')
                this.props.setLoginSuccess(true);
                this.props.history.push(`/home`);
            })
            .catch(error => {
                this.setState({
                    error_message: "E-posta/şifre kombinasyonu hatalıdır."
                });
                console.log('logged error')
                this.props.setLoginSuccess(false);
            })
            .finally(() => {
                this.props.spinner(false);
            });
    };

    registerHandler = e => {
        e.preventDefault();
        this.props.spinner(true);

        let name = this.refs.name.value;
        let email = this.refs.email.value;
        let password = this.refs.password.value;
        let c_password = this.refs.c_password.value;
        let agreement = this.refs.agreement.checked;

        if (this.validateHander(name, email, password, c_password, agreement)) {
            register(name, email, password, c_password)
                .then(response => {
                    this.setState({
                        success_message: "You have Successfully Registered"
                    });
                })
                .catch(error => {
                    if (error.response.status == 422) {
                        // error.response.data.errors.map((error,field)=>{
                        //     console.log(error,field);
                        // })
                        console.log(error.response.data.message);
                        for (var key in error.response.data.errors) {
                            console.log(
                                key,
                                error.response.data.errors[key][0]
                            );
                            this.setState({
                                errors: {
                                    ...this.state.errors,
                                    [key]: error.response.data.errors[key][0]
                                }
                            });
                        }
                        // console.log(error.response.data, error.response.data.errors);
                    } else {
                        this.setState({
                            error_message: error.response.data.message
                        });
                    }
                })
                .finally(() => {
                    this.props.spinner(false);
                });
        }
    };

    validateHander = (name, email, password, c_password, agreement) => {
        console.log(name, email, password, c_password, agreement);
        //unset all the errors
        this.setState({
            errors: {
                name: "",
                email: "",
                password: "",
                c_password: "",
                agreement: ""
            }
        });

        let name_error = "";
        let email_error = "";
        let password_error = "";
        let c_password_error = "";
        let agreement_error = "";

        if (name.trim() == "") {
            name_error = "Name is Required";
        }

        if (email.trim() == "") {
            email_error = "Email is Required";
        }

        if (password.trim() == "") {
            password_error = "Password is Required";
        }

        if (c_password.trim() == "") {
            c_password_error = "Confirm password is Required";
        } else if (c_password != password) {
            c_password_error = "Given Passwords Doesn't match";
        }

        if (!agreement) {
            agreement_error =
                "You have to agree to terms and conditions to register ";
        }

        this.setState({
            errors: {
                name: name_error,
                email: email_error,
                password: password_error,
                c_password: c_password_error,
                agreement: agreement_error
            }
        });

        if (
            name_error ||
            email_error ||
            password_error ||
            c_password_error ||
            agreement_error
        ) {
            return false;
        }

        return true;
    };

    render() {
        let { email, password } = this.state;
        let { isLoginPending, isLoginSuccess } = this.props;
        let { view } = this.state;
        let { errors } = this.state;

        return (
            <div className="login-page">
                <header className="navbar navbar-expand navbar-dark flex-row bd-navbar">
                    <div className="container">
                        <div className="logo">
                            <a href="">
                                <img src={Logo} alt="Indivi Exam" />
                            </a>
                        </div>
                        <nav>
                            <ul className="top-menu-list list-inline">
                                <li className="list-inline-item">
                                    <a href="">About Us</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="">COntact</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header>
                <main role="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md lr-forms-area">
                                <div className="lr-forms-content">
                                    <div className="welcome">
                                        <h2>Welcome</h2>
                                        <p>
                                            {/* Hesap bilgilerinizi girerek giriş
                                            yapabilirsiniz. Henüz hesabınız
                                            yoksa{" "}
                                            <a href="" title="Kaydol">
                                                yeni hesap
                                            </a>{" "}
                                            oluşturabilirsiniz. */}
                                            You can login by entering your account information. If you don't have an account yet, you can create a{" "}
                                        </p>
                                    </div>
                                    <div className="lr-forms">
                                        <nav>
                                            {this.state.error_message!= "" &&
                                                this.state.view ==
                                                    "register" && (
                                                    <div className="alert alert-danger">
                                                        {
                                                            this.state
                                                                .error_message
                                                        }
                                                    </div>
                                                )}
                                            {this.state.success_message && (
                                                <div className="alert alert-success">
                                                    {this.state.success_message}
                                                </div>
                                            )}
                                            <div
                                                className="nav nav-tabs"
                                                id="nav-tab"
                                                role="tablist"
                                            >
                                                <a
                                                    className={
                                                        view == "login"
                                                            ? "nav-item nav-link active"
                                                            : "nav-item nav-link"
                                                    }
                                                    role="tab"
                                                    aria-controls="nav-login"
                                                    aria-selected="true"
                                                    onClick={() => {
                                                        this.changeView(
                                                            "login"
                                                        );
                                                    }}
                                                >
                                                    {/* Giriş Yap */}
                                                    Login
                                                </a>
                                                <a
                                                    className={
                                                        view == "register"
                                                            ? "nav-item nav-link active"
                                                            : "nav-item nav-link"
                                                    }
                                                    id="nav-register-tab"
                                                    data-toggle="tab"
                                                    role="tab"
                                                    aria-controls="nav-register"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                        this.changeView(
                                                            "register"
                                                        );
                                                    }}
                                                >
                                                    {/* Kaydol */}
                                                    Register
                                                </a>
                                            </div>
                                        </nav>
                                        <div
                                            className="tab-content"
                                            id="nav-tabContent"
                                        >
                                            <div
                                                className={
                                                    view == "login"
                                                        ? "tab-pane fade show active"
                                                        : "tab-pane fade"
                                                }
                                                id="nav-login"
                                                role="tabpanel"
                                                aria-labelledby="nav-login-tab"
                                            >
                                                <form
                                                    onSubmit={this.loginHandler}
                                                >
                                                    {this.state
                                                        .error_message != "" && (
                                                        <p className="form-invalid text-danger">
                                                            {
                                                                this.state
                                                                    .error_message
                                                            }
                                                        </p>
                                                    )}
                                                    <div className="form-group">
                                                        <label htmlFor="user-name">
                                                            {/* kullanıcı adı */}
                                                            User Name
                                                        </label>
                                                        <input
                                                            type="email"
                                                            required="required"
                                                            className="form-control"
                                                            ref="login_email"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="user-password">
                                                            {/* şifre */}
                                                            Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            required="required"
                                                            className="form-control"
                                                            ref="login_password"
                                                        />
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="remember"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="remember"
                                                        >
                                                            {/* Beni hatırla */}
                                                            Remember me
                                                        </label>
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary orange-btn"
                                                    >
                                                        Giriş
                                                    </button>
                                                    <div className="form-text-link">
                                                        <a
                                                            href=""
                                                            title="Şifre Hatırlat"
                                                        >
                                                            {/* Şifreni mi unuttun? */}
                                                            Forgot your password?
                                                        </a>
                                                    </div>
                                                </form>
                                            </div>
                                            <div
                                                className={
                                                    view == "register"
                                                        ? "tab-pane fade show active"
                                                        : "tab-pane fade"
                                                }
                                                id="nav-register"
                                                role="tabpanel"
                                                aria-labelledby="nav-register-tab"
                                            >
                                                <form
                                                    onSubmit={
                                                        this.registerHandler
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <label htmlFor="add-user-name">
                                                            User Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                errors.name
                                                                    ? "form-control is-invalid"
                                                                    : "form-control "
                                                            }
                                                            ref="name"
                                                        />
                                                        {errors.name && (
                                                            <div className="invalid-feedback">
                                                                {errors.name}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="ass-user-email">
                                                            e-mail
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className={
                                                                errors.email
                                                                    ? "form-control is-invalid"
                                                                    : "form-control "
                                                            }
                                                            ref="email"
                                                        />
                                                        {errors.email && (
                                                            <div className="invalid-feedback">
                                                                {errors.email}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col">
                                                            <label htmlFor="user-password1">
                                                                password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className={
                                                                    errors.password
                                                                        ? "form-control is-invalid"
                                                                        : "form-control "
                                                                }
                                                                ref="password"
                                                            />
                                                            {errors.password && (
                                                                <div className="invalid-feedback">
                                                                    {
                                                                        errors.password
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="form-group col">
                                                            <label htmlFor="user-password2">
                                                                password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className={
                                                                    errors.c_password
                                                                        ? "form-control is-invalid"
                                                                        : "form-control "
                                                                }
                                                                ref="c_password"
                                                            />
                                                            {errors.c_password && (
                                                                <div className="invalid-feedback">
                                                                    {
                                                                        errors.c_password
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <input
                                                            type="checkbox"
                                                            className={
                                                                errors.agreement
                                                                    ? "form-check-input is-invalid"
                                                                    : "form-check-input"
                                                            }
                                                            ref="agreement"
                                                            id="agreement"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="agreement"
                                                        >
                                                            <a href="">
                                                                {/* Kullanım
                                                                şartlarını */}
                                                                Terms of Use
                                                            </a>{" "}
                                                        </label>
                                                        {errors.agreement && (
                                                            <div className="invalid-feedback">
                                                                {
                                                                    errors.agreement
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary orange-btn"
                                                    >
                                                        {/* Kaydol */}
                                                        Register
                                                    </button>
                                                    <div className="form-text-link">
                                                        <a
                                                            href=""
                                                            title="Giriş"
                                                        >
                                                            {/* Zaten hesabın varsa
                                                            giriş için tıkla. */}
                                                            Click here to login if you already have an account.
                                                        </a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md slider-area">
                                <div
                                    id="carouselIndicators"
                                    className="carousel slide"
                                    data-ride="carousel"
                                >
                                    <ol className="carousel-indicators">
                                        <li
                                            data-target="#carouselIndicators"
                                            data-slide-to="0"
                                            className="active"
                                        ></li>
                                        <li
                                            data-target="#carouselIndicators"
                                            data-slide-to="1"
                                        ></li>
                                    </ol>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img
                                                className=""
                                                src={Slider1}
                                                alt=""
                                            />
                                            <div className="carousel-caption">
                                                <h5>
                                                    {/* Uzman Sınav Mobil Uyumludur */}
                                                    Indivi Exam is Mobile Compatible
                                                </h5>
                                                <p>
                                                    {/* Dilediğin cihazdan Uzman
                                                    Sınavı takip edebilir,
                                                    istatistiklerini görebilir,
                                                    sınavlarını tekrar
                                                    çözebilirsin. */}
                                                    You can follow the Indivi Exam from any device, see the statistics, and solve the exams again.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <img
                                                className=""
                                                src={Slider2}
                                                alt=""
                                            />
                                            <div className="carousel-caption">
                                                <h5>
                                                    {/* Takvimi kullanarak hiçbir
                                                    sınavı kaçırmayın */}
                                                    Don't miss any exam using the calendar
                                                </h5>
                                                <p>
                                                    {/* Size özel oluşturulan
                                                    takvimi kullanarak sınav
                                                    tarihlerini takip edebilir,
                                                    yaklaşan sınavlara ait
                                                    bildirim alabilirsiniz. */}
                                                    You can follow the exam dates using the calendar created specifically for you, and receive notifications about upcoming exams.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                        className="carousel-control-prev"
                                        href="#carouselIndicators"
                                        role="button"
                                        data-slide="prev"
                                    >
                                        <span
                                            className="carousel-control-prev-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="sr-only">
                                            Previous
                                        </span>
                                    </a>
                                    <a
                                        className="carousel-control-next"
                                        href="#carouselIndicators"
                                        role="button"
                                        data-slide="next"
                                    >
                                        <span
                                            className="carousel-control-next-icon"
                                            aria-hidden="true"
                                        ></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Spinner />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoginPending: state.isLoginPending,
        isLoginSuccess: state.isLoginSuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        spinner: show => dispatch(spinner(show)),
        setLoginSuccess: status => dispatch(setLoginSuccess(status))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
