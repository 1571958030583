import React from "react";
import { connect } from "react-redux";
import { spinner } from "../../actions/spinner";
import ExamHeader from "./ExamHeader";
import Header from "../Header";
import BadgeYellow from "../../styles/img/badge-2-yellow-big.svg";
import Badge1 from "../../styles/img/badge-1.svg";
import LeftOptionBar from "./LeftOptionBar";
// import ReactHtmlParser from 'react-html-parser';
import { ANS_OPTIONS } from "../../services/constants";
import { NotificationContainer } from "react-notifications";
import { createNotification } from "../../services/utils";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Modal from "../UI/Modal";

import {
    favoriteQuestion,
    answerQuestion,
    changeSearchCourseText,
    changeShowQuestion
} from "../../actions/exam";
import Spinner from "../Spinner";

class PrintedExam extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    state = {
        selectedAnswerId: null,
        questionId: null,
        isModalOpen: false,
        quitExam: false
    };

    componentWillMount() {
        if (localStorage.auth_token == "null") {
            // console.log("logged out!!!");
            window.location = "/login";
        } // TODO ADD TOKEN VALIDATION
    }

    nextQuestion = () => {
        this.props.dispatch(changeShowQuestion(this.props.question_show + 1));
        this.myRef.current && (this.myRef.current.scrollTop = 0);
    };

    previousQuestion = () => {
        this.props.dispatch(changeShowQuestion(this.props.question_show - 1));
        this.myRef.current && (this.myRef.current.scrollTop = 0);
    };

    toggleFavorite = status => {
        this.props.dispatch(favoriteQuestion(this.props.questionIndex, status));
        // console.log("key:> ", this.props.questionIndex);
    };

    changeFilterCourse = course_id => {
        console.log("changed filter: ", course_id);
        this.props.dispatch(changeSearchCourseText(course_id));
    };
    changeFilterCourseBySelect = e => {
        this.props.dispatch(changeSearchCourseText(e.target.value));
    };
    answerTheQuestion = (ans, answerKey, isTrue, answerId) => {
        // console.log("e", ans, answerKey, isTrue);
        const { questionIndex } = this.props;
        // this.setState({
        //     selectedAnswerId: ans.id
        // });

        this.props.dispatch(
            answerQuestion(questionIndex, answerKey, isTrue, answerId)
        );
    };
    clickAnswer = selectedAnswerId => {
        const { questionIndex } = this.props;

        // console.log(selectedAnswerId,this.props.questions[questionIndex].answer, "clicked", this.props.questions[questionIndex].answer===selectedAnswerId);
        this.props.questions[questionIndex].answer === selectedAnswerId &&
            this.props.dispatch(
                answerQuestion(questionIndex, null, null, null)
            );
    };
    showExamInfo = () => {
        // console.log("showExamInfo");
        createNotification(
            "info",
            this.props.exam_instruction,
            this.props.exam_name + " Hakkında",
            10000
        );
    };
    quitModalHandler = e => {
        e.preventDefault(); //i added this to prevent the default behavior
        this.setState({
            quitExam: true, //it affects the exam header, so examheader can finish the exam.
            isModalOpen: false
        });
    };
    cancelQuitModalHandler = e => {
        e.preventDefault(); //i added this to prevent the default behavior
        this.setState({
            isModalOpen: false
        });
    };
    showExitModal = () => {
        this.setState({
            isModalOpen: true
        });
    };
    render() {
        // console.log("om render props", this.props);
        const {
            question_show,
            questions,
            exam_state,
            exam_enter_status,
            filteredQuestions,
            questionIndex,
            courses,
            course_groups,
            course_representation_type
        } = this.props;

        const totalQuestions = questions.length;

        const filterTypeCourses =
            course_representation_type === 0 ? courses : course_groups; //replacing the course to course_group if course_representation_type is to show groups
        const shortFilterLength = course_representation_type === 0 ? 12 : 30;
        const totalFilteredQuestions = filteredQuestions.length;
        const questionFavorited = filteredQuestions[question_show - 1]
            ? filteredQuestions[question_show - 1].favorited
            : false;

        let trueQuestions = 0;
        let emptyQuestions = 0;
        let falseQuestions = 0;
        let netFromAllCourses = 0;

        let questionsByCourses = {};
        courses.map(courseItem => {
            courseItem.trueQuestions = 0;
            courseItem.falseQuestions = 0;
            courseItem.emptyQuestions = 0;
            if (!questionsByCourses[courseItem.id]) {
                questionsByCourses[courseItem.id] = courseItem;
            }
        });

        questions.map(q => {
            if (q.isTrue === 1) {
                questionsByCourses[q.course_id].trueQuestions++;
                trueQuestions++;
            } else if (q.isTrue === 0) {
                falseQuestions++;
                questionsByCourses[q.course_id].falseQuestions++;
            } else {
                emptyQuestions++;
                questionsByCourses[q.course_id].emptyQuestions++;
            }
        });

        // const ornekMatDogru=questions.
        // console.log(questionsByCourses, "questionsByCourses");
        // console.log(trueQuestions, emptyQuestions, falseQuestions);
        const hasNextQuestion = question_show < totalFilteredQuestions;
        const hasPreviousQuestion = question_show - 1 > 0;
        if (localStorage.auth_token == "null") {
            return <div>Not Logged In</div>;
        } else if (
            exam_state === "finished" ||
            exam_enter_status === "finished"
        ) {
            return (
                <div className="exam-inner-result ">
                    {" "}
                    <NotificationContainer />
                    <Header />
                    <div className="outer-all-wrap">
                        <main className="main-content-wrapper exam-pge exam-end-pge">
                            <div className="exam-question-wrapper style-2">
                                {this.props.exam_status != 0 ? (
                                    <div className="exam-over-summary">
                                        <p>
                                            <strong className="d-block">
                                                {exam_enter_status ===
                                                "finished"
                                                    ? "Bu sınava girmiştiniz."
                                                    : "Sınavınız bitti."}
                                            </strong>{" "}
                                            {/* Your exam result will be announced
                                        within 7 days. */}
                                        </p>
                                        {/* <p>
                                            <strong className="d-block">
                                                Sınav Özetiniz:
                                            </strong>
                                            Toplam Soru:
                                            <strong>
                                                {" " + totalQuestions}
                                            </strong>
                                            <br />
                                            Doğru Cevap:
                                            <strong>
                                                {" " + trueQuestions}
                                            </strong>
                                            <br />
                                            Yanlış Cevap:
                                            <strong>
                                                {" " + falseQuestions}
                                            </strong>
                                            <br />
                                            Boş Bırakılan Soru Sayısı:
                                            <strong>
                                                {" " + emptyQuestions}
                                            </strong>
                                        </p> */}
                                        <div className="exam-over-summary">
                                            <h5 className="d-block">
                                                Sınav Özetiniz:
                                            </h5>
                                            <br />
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Dersler:</th>
                                                        <th>Doğru:</th>
                                                        <th>Yanlış</th>
                                                        <th>Boş:</th>
                                                        <th>Net:</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(
                                                        questionsByCourses
                                                    ).map(([key, cItem]) => {
                                                        let netTrue =
                                                            cItem.falseQuestions >
                                                            0
                                                                ? cItem.trueQuestions -
                                                                  (
                                                                      cItem.falseQuestions /
                                                                      4
                                                                  ) //TODO Set a variable on backend for this.
                                                                      .toFixed(
                                                                          2
                                                                      )
                                                                : cItem.trueQuestions;

                                                        netTrue = //check iff net is small than 0
                                                            netTrue < 0
                                                                ? 0
                                                                : netTrue;
                                                        netFromAllCourses += netTrue;

                                                        return (
                                                            <tr key={key}>
                                                                <th>
                                                                    {
                                                                        cItem.short_name
                                                                    }
                                                                </th>
                                                                <td>
                                                                    {
                                                                        cItem.trueQuestions
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        cItem.falseQuestions
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        cItem.emptyQuestions
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {netTrue}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <th>TOPLAM</th>
                                                        <td>{trueQuestions}</td>
                                                        <td>
                                                            {falseQuestions}
                                                        </td>
                                                        <td>
                                                            {emptyQuestions}
                                                        </td>
                                                        <td>
                                                            {netFromAllCourses}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <p>
                                            Anasayfaya dönmek için{" "}
                                            <a href="/home">buraya</a>{" "}
                                            tıklayınız.
                                        </p>
                                    </div>
                                ) : (
                                    <div className="exam-over-summary">
                                        <p>
                                            <strong className="d-block">
                                                Sınavınız bitti. Sınav bitiş
                                                tarihinden sonra, kısa zaman
                                                içerisinde sonuçlar size e-posta
                                                olarak gönderilecektir.{" "}
                                            </strong>{" "}
                                            {/* Your exam result will be announced
                                        within 7 days. */}
                                        </p>
                                        <p>
                                            Anasayfaya dönmek için{" "}
                                            <a href="/home">buraya</a>{" "}
                                            tıklayınız.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </main>
                        <div className="footer-text bdr-top d-none d-lg-block">
                            Uzman Sınav © 2020 &nbsp; by MaviRobot Eğitim
                            Teknolojileri &nbsp; <span>|</span>
                            <a href="#">Hakkımızda</a> <a href="#">İletişim</a>
                        </div>
                    </div>
                </div>
            ); //TODO FIX THE FOOTER ON RESPONSIVE
        } else {
            const shouldBeShortFilterNames =
                course_representation_type === 0 &&
                this.props.courses.length > 5
                    ? true
                    : false;

            return (
                <div className="exam-page">
                    <NotificationContainer />
                    <ExamHeader
                        exitHandler={this.showExitModal}
                        exitStatus={this.state.quitExam}
                    />

                    <div className="outer-all-wrap">
                        <main>
                            <div className="main-content-wrapper exam-pge">
                                <Modal show={this.state.isModalOpen}>
                                    <div>
                                        <span className="modal-text">
                                            Sınavı bitirmek istediğinizden emin
                                            misiniz?
                                        </span>
                                        <div className="modal-buttons">
                                            <button
                                                className="box-btn style-2 white"
                                                onClick={
                                                    this.cancelQuitModalHandler
                                                }
                                            >
                                                <span>Geri Dön</span>
                                            </button>
                                            <button
                                                className="box-btn style-2 red"
                                                onClick={this.quitModalHandler}
                                            >
                                                <span>Sınavı Bitir</span>
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                                <div className="exam-question-wrapper">
                                    <div
                                        className="input-inside exam-name small-screen-exam-title"
                                        onClick={this.showExamInfo}
                                    >
                                        <h2>{this.props.exam_name}</h2>
                                        {/* <span className="sc-icon-small">
                                        <i className="fal fa-info-circle fa-xs"></i>
                                    </span> */}
                                    </div>
                                    <LeftOptionBar />

                                    <div className="exam-question-wrap">
                                        <div className="question-header">
                                            <div className="all-question-btns">
                                                <div className="course-select">

                                                        <select
                                                            onChange={
                                                                this
                                                                    .changeFilterCourseBySelect
                                                            }
                                                            value={
                                                                parseInt(
                                                                    this.props
                                                                        .exam
                                                                        .searchCourseText
                                                                ) || 0
                                                            }
                                                        >
                                                            }
                                                            {filterTypeCourses &&
                                                                filterTypeCourses.map(
                                                                    (
                                                                        course,
                                                                        key
                                                                    ) => {

                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    course.id
                                                                                }
                                                                                label={
                                                                                    course.short_name
                                                                                }
                                                                                key={
                                                                                    course.id
                                                                                }
                                                                            ></option>
                                                                        );
                                                                    }
                                                                )}
                                                        </select>

                                                </div>
                                                <button
                                                    className={`box-btn ${
                                                        this.props.exam
                                                            .searchCourseText ===
                                                        ""
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={this.changeFilterCourse.bind(
                                                        this,
                                                        ""
                                                    )}
                                                >
                                                    Tüm Dersler
                                                </button>

                                                {filterTypeCourses &&
                                                    filterTypeCourses.map(
                                                        (course, key) => {
                                                            return (
                                                                <button
                                                                    key={
                                                                        course.id
                                                                    }
                                                                    className={`box-btn ${
                                                                        parseInt(
                                                                            this
                                                                                .props
                                                                                .exam
                                                                                .searchCourseText
                                                                        ) ===
                                                                        course.id
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                    onClick={this.changeFilterCourse.bind(
                                                                        this,
                                                                        course.id
                                                                    )}
                                                                >
                                                                    {shouldBeShortFilterNames
                                                                        ? course.short_name.length > shortFilterLength
                                                                            ?
                                                                            course.short_name.substring(0,shortFilterLength -2) +
                                                                              ".."
                                                                            :
                                                                            course.short_name
                                                                        : course.short_name}
                                                                    {/* <span>
                                                                        (30)
                                                                    </span> */}
                                                                </button>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                        <div className="question-content">
                                            <div
                                                className="single-question-scroll-wrap"
                                                ref={this.myRef}
                                            >
                                                {questionIndex !== undefined ? (
                                                    <SimpleBar className="simple-bar">
                                                        {this.props
                                                            .question && (
                                                            <div className="single-question">
                                                                <div className="question-sl">
                                                                    <span className="question-number">
                                                                        <span className="question-course">
                                                                            {this
                                                                                .props
                                                                                .question &&
                                                                                this.props.courses.map(
                                                                                    cours => {
                                                                                        const Cname =
                                                                                            cours.id ==
                                                                                            this
                                                                                                .props
                                                                                                .question
                                                                                                .course_id
                                                                                                ? cours.short_name +
                                                                                                  " sorusu"
                                                                                                : "";
                                                                                        return Cname;
                                                                                    }
                                                                                )}
                                                                        </span>
                                                                        {
                                                                            this
                                                                                .props
                                                                                .question_show
                                                                        }
                                                                        .
                                                                    </span>

                                                                    <img
                                                                        src={
                                                                            questionFavorited
                                                                                ? BadgeYellow
                                                                                : Badge1
                                                                        }
                                                                        alt=""
                                                                        onClick={this.toggleFavorite.bind(
                                                                            this,
                                                                            !questionFavorited
                                                                        )}
                                                                        style={{
                                                                            cursor:
                                                                                "pointer"
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="question-text-wrap">
                                                                    <div>
                                                                        <div>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: this
                                                                                        .props
                                                                                        .question
                                                                                        .root
                                                                                }}
                                                                                className="question-text noselect"
                                                                            />

                                                                            <div className="answer-optons noselect">
                                                                                {this.props.question.answers_in_random_order.map(
                                                                                    (
                                                                                        ans,
                                                                                        answer_key
                                                                                    ) => {
                                                                                        return (
                                                                                            <label
                                                                                                htmlFor={
                                                                                                    "radio" +
                                                                                                    ans.id
                                                                                                }
                                                                                                key={
                                                                                                    ans.id
                                                                                                }
                                                                                                className={
                                                                                                    this
                                                                                                        .props
                                                                                                        .question
                                                                                                        .answer ===
                                                                                                    ans.id
                                                                                                        ? "active"
                                                                                                        : ""
                                                                                                }
                                                                                            >
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    name={
                                                                                                        "radio" +
                                                                                                        ans.id
                                                                                                    }
                                                                                                    id={
                                                                                                        "radio" +
                                                                                                        ans.id
                                                                                                    }
                                                                                                    value={
                                                                                                        "radio" +
                                                                                                        ans.id
                                                                                                    }
                                                                                                    checked={
                                                                                                        this
                                                                                                            .props
                                                                                                            .question
                                                                                                            .answer ===
                                                                                                        ans.id
                                                                                                    }
                                                                                                    onChange={this.answerTheQuestion.bind(
                                                                                                        this,
                                                                                                        ans,
                                                                                                        ANS_OPTIONS[
                                                                                                            answer_key
                                                                                                        ],
                                                                                                        ans.true,
                                                                                                        ans.id
                                                                                                    )}
                                                                                                    onClick={this.clickAnswer.bind(
                                                                                                        this,
                                                                                                        ans.id
                                                                                                    )}
                                                                                                />
                                                                                                <span className="mcq-option">
                                                                                                    {
                                                                                                        ANS_OPTIONS[
                                                                                                            answer_key
                                                                                                        ]
                                                                                                    }
                                                                                                </span>

                                                                                                <div
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            ans.answer
                                                                                                    }}
                                                                                                ></div>
                                                                                            </label>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        {/*<h4>Yukarıdaki boşluğa hangisi gelmelidir?</h4>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </SimpleBar>
                                                ) : (
                                                    <span className="filter-result-null-text">
                                                        Gösterilecek soru yok.
                                                    </span>
                                                )}
                                                {/* </div> */}
                                                <div className="twin-btn-wrap noselect">
                                                    <div className="exam-bottom">
                                                        {/* <div
                                                            className="input-inside exam-name"
                                                            onClick={
                                                                this
                                                                    .showExamInfo
                                                            }
                                                        >
                                                            <label>
                                                                {
                                                                    this.props
                                                                        .exam_name
                                                                }
                                                            </label>
                                                        </div> */}
                                                        <div className="questions-summary">
                                                            <div className="summary-block">
                                                                <span className="questions-summary-text">
                                                                    Boş:
                                                                </span>
                                                                <span className="questions-summary-number">
                                                                    {
                                                                        emptyQuestions
                                                                    }
                                                                </span>
                                                            </div>
                                                            <span className="questions-summary-divider">
                                                                /
                                                            </span>
                                                            <div className="summary-block">
                                                                <span className="questions-summary-text">
                                                                    Cevaplanan:
                                                                </span>
                                                                <span className="questions-summary-number">
                                                                    {trueQuestions +
                                                                        falseQuestions}
                                                                </span>
                                                            </div>
                                                            {/* {`Boş ${emptyQuestions} \u00A0\u00A0 | \u00A0\u00A0 Cevaplanmış: ${trueQuestions +
                                                                falseQuestions} `} */}
                                                        </div>
                                                    </div>
                                                    <a
                                                        className={
                                                            "box-btn style-2 " +
                                                            (hasPreviousQuestion
                                                                ? " twin-btn-wrap-enabled"
                                                                : "twin-btn-wrap-disabled")
                                                        }
                                                        onClick={
                                                            hasPreviousQuestion
                                                                ? this
                                                                      .previousQuestion
                                                                : undefined
                                                        }
                                                    >
                                                        Önceki Soru
                                                    </a>
                                                    <a
                                                        className={
                                                            "box-btn style-2 " +
                                                            (hasNextQuestion
                                                                ? "twin-btn-wrap-enabled"
                                                                : " twin-btn-wrap-disabled")
                                                        }
                                                        onClick={
                                                            hasNextQuestion
                                                                ? this
                                                                      .nextQuestion
                                                                : undefined
                                                        }
                                                    >
                                                        Sonraki Soru
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="exam-status-wrap">
                                    <div className="all-type-btn gradee">
                                        <button className="text-black">
                                            A+
                                        </button>
                                        <button>A-</button>
                                    </div>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    const {
        questions,
        searchCourseText,
        exam_enter_status,
        question_show,
        leftBarFilter,
        course_representation_type //0 Represent as course , 1 - Show as group, 2 Both
    } = state.exam;
    let questionsToShowAfterCourseFilters;
    if (course_representation_type === 1) {
        console.log("here we go course_representation_type is 1 ");
        questionsToShowAfterCourseFilters =
            searchCourseText === ""
                ? questions
                : questions.filter(
                      question => question.course_group_id == searchCourseText
                  );
    } else {
        questionsToShowAfterCourseFilters =
            searchCourseText === ""
                ? questions
                : questions.filter(
                      question => question.course_id == searchCourseText
                  );
    }

    // const questionsToShowAfterCourseFilters =
    //     state.exam.searchCourseText === ""
    //         ? state.exam.questions
    //         : state.exam.questions.filter(
    //               question => question.course_id == state.exam.searchCourseText
    //           );
    const filteredQuestions =
        leftBarFilter === "ALL"
            ? questionsToShowAfterCourseFilters
            : questionsToShowAfterCourseFilters.filter(question => {
                  switch (leftBarFilter) {
                      case "FAVORITE":
                          return question.favorited;
                          break;
                      case "EMPTY":
                          return question.answer === null;
                          break;
                      case "FULL":
                          return question.answer !== null;
                          break;
                      default:
                          break;
                  }
              });

    // console.log("filteredQuestions", filteredQuestions);
    const question = filteredQuestions[question_show - 1];
    // console.log("question", question);
    const questionIndex =
        question !== undefined
            ? state.exam.questions.findIndex(q => q.id === question.id)
            : undefined;

    return {
        courses: state.exam.courses,
        course_groups: state.exam.course_groups,
        questions,
        question_show,
        exam: state.exam,
        exam_name: state.exam.name,
        exam_instruction: state.exam.instruction,
        exam_start_time: state.exam.exam_start_time,
        // questions: state.exam.questions,
        exam_state: state.exam.exam_state,
        exam_status: state.exam.status,
        exam_enter_status,
        searchCourseText,
        filteredQuestions,
        question,
        questionIndex,
        result: state.exam.result,
        leftBarFilter,
        course_representation_type

        // question: state.exam.questions[questionIndex]
    };
};

export default connect(mapStateToProps)(PrintedExam);
