import React from "react";
import { connect } from "react-redux";

import QuestionMark from "../../styles/img/question-mark-2.svg";
import Timer from "../../styles/img/timer.svg";
import Warning from "../../styles/img/warning-2.svg";

import { Link, withRouter } from "react-router-dom";
import { spinner } from "../../actions/spinner";
import { startExam } from "../../actions/exam";
import { Redirect } from "react-router-dom";
import { examStartByCode, examStartByID } from "../../services/api";
import { setShowIntroductionData } from "../../actions/dashboard";

// import { storeAnswers, submitExam } from "../../actions/exam";
import {
    createNotification
    // get_formatted_answers
} from "../../services/utils";
// import { saveAnswers } from "../../services/api";

// import { logout } from "../../services/api";

class ExamIntroduction extends React.Component {
    componentDidMount() {}
    state = {
        redirectToExam: false
    };
    examStartHandler = id => {
        console.log(id);
        this.props.dispatch(spinner(true));

        examStartByID(id)
            .then(response => {
                this.props.dispatch(startExam(response.data));
                this.props.dispatch(spinner(false));
                this.setState({
                    redirectToExam: "exam/" + response.data.id
                });
            })
            .catch(error => {
                console.log("Error", error.response, error.response);
                console.log(error);
                if (
                    error.response &&
                    (error.response.status == 400 ||
                        error.response.status == 403)
                ) {
                    createNotification("warning", error.response.data.message);
                } else {
                    createNotification(
                        "Hata",
                        "Bu sınava giremezsiniz. Lütfen sistem yöneticinize başvurunuz."
                    );
                }
                this.props.dispatch(spinner(false));
            });
    };

    cancelRedirect = () => {
        console.log("redirect cancelled");
        this.setState({
            redirectToExam: "false"
        });
    };

    render() {
        const { redirectToExam } = this.state;

        if (redirectToExam) {
            return <Redirect to={redirectToExam} />;
        }
        const {
            showingIntroduction,
            examIdForIntroduction,
            examCodeForIntroduction,
            activeExams
        } = this.props;
        console.log("activeExams", activeExams);
        const currentExam = examIdForIntroduction
            ? activeExams.find(element => element.id === examIdForIntroduction)
            : activeExams.find(
                  element => element.exam_code === examCodeForIntroduction
              );

        console.log("showingIntroduction", showingIntroduction);
        console.log("currentExam", currentExam);
        return showingIntroduction && currentExam ? (
            <div>
                <div className="primary-title">
                    <h2>{currentExam.name}</h2>
                </div>

                <div className="content-block-wrap">
                    <h3 className="tertiary-title">Exam Summary</h3>
                    <div className="row single-s-card-container">
                        <div >
                            <div className="single-s-card">
                                <div className="s-img">
                                    <img src={QuestionMark} alt="" />
                                </div>
                                <h4>{currentExam.questions_count} Question</h4>
                            </div>
                        </div>
                        <div >
                            <div className="single-s-card light-indigo-bg">
                                <div className="s-img">
                                    <img src={Timer} alt="" />
                                </div>
                                <h4>{currentExam.exam_time} Minutes</h4>
                            </div>
                        </div>
                        {/* <div className="col-xl-4">
                            <div className="single-s-card light-red-bg mb-0">
                                <div className="s-img">
                                    <img src={Warning} alt="" />
                                </div>
                                <h4>Dikkat</h4>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="content-block-wrap">
                    <h3 className="tertiary-title">
                        Exam Instructions
                    </h3>
                    <div className="content-block">
                        <div className="user-guide-wrap home-pge">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: currentExam.instruction
                                }}
                            ></p>
                            <div className="middle-btn text-center mt-30">
                                <a
                                    href="#"
                                    className="box-btn style-2 yellow-bg"
                                    id="msg"
                                    onClick={e => {
                                        e.preventDefault();

                                        this.examStartHandler(currentExam.id);
                                    }}
                                >
                                    Start
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div>
                Wrong Code
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeExams: state.dashboard.activeExams,
        showingIntroduction: state.dashboard.showingIntroduction,
        examIdForIntroduction: state.dashboard.examIdForIntroduction,
        examCodeForIntroduction: state.dashboard.examCodeForIntroduction
    };
};
// const mapDispatchToProps = dispatch => {
//     return {
//         setShowIntroductionData: data => dispatch(setShowIntroductionData(data))
//     };
// };
export default withRouter(connect(mapStateToProps)(ExamIntroduction));
