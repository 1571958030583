import {NotificationContainer, NotificationManager} from 'react-notifications';

export const createNotification = (type, message, title, timeout = 5000) => {
    console.log('type>>>>>', type);
    switch (type) {
        case 'info':
            NotificationManager.info(message, title, timeout);
            break;
        case 'success':
            NotificationManager.success(message, title, timeout);
            break;
        case 'warning':
            NotificationManager.warning(message, title, timeout);
            break;
        case 'error':
            NotificationManager.error(message, title, timeout);
            break;
    }

};

export const get_formatted_answers = (questions, temp_ques_ans = null) => {
    let formatted_data = {};
    if (temp_ques_ans != null) {
        questions.forEach((question, index) => {
            if (temp_ques_ans.includes(index)) {
                formatted_data[question.id] = question.answer;
            }
        });
    } else {
        questions.forEach((question) => {
            formatted_data[question.id] = question.answer;
        });
    }

    return formatted_data;
};


