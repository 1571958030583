import React from "react";
import { connect } from "react-redux";
import { examCheckByCode } from "../../services/api";
import { createNotification } from "../../services/utils";
import { spinner } from "../../actions/spinner";
// import { startExam } from "../../actions/exam";
// import { Redirect } from "react-router-dom";
import { setShowIntroductionData } from "../../actions/dashboard";

class ExamStartByCode extends React.Component {
    // state = {
    //     redirectToExam: false
    // };
    examStartHandler = () => {
        console.log(this.refs.exam_code.value);
        if (this.refs.exam_code.value.trim() == "") {
            createNotification(
                "warning",
                "Please Fill out the Exam Code to Start the exam"
            );
            return;
        }

        // };
        this.props.spinner(true);

        examCheckByCode(this.refs.exam_code.value)
            .then(response => {
                // this.props.dispatch(startExam(response.data));
                console.log(response.data);
                this.props.setShowIntroductionData({
                    showingIntroduction: true,
                    examIdForIntroduction: null,
                    examCodeForIntroduction: this.refs.exam_code.value
                });
                this.props.spinner(false);

                // this.setState({
                //     redirectToExam: "exam/" + response.data.id
                // });
            })
            .catch(error => {
                //
                if (
                    error.response &&
                    (error.response.status == 400 ||
                        error.response.status == 403)
                ) {
                    createNotification("warning", error.response.data.message);
                } else {
                    createNotification(
                        "Hata",
                        "Bu sınava giremezsiniz. Lütfen sistem yöneticinize başvurunuz."
                    );
                }
                this.props.spinner(false);
            });
    };

    // cancelRedirect = () => {
    //     console.log("redirect cancelled");
    //     this.setState({
    //         redirectToExam: "false"
    //     });
    // };
    render() {
        // const { redirectToExam } = this.state;

        // if (redirectToExam) {
        //     return <Redirect to={redirectToExam} />;
        // }
        return (
            <div className="content-block-wrap">
                <h3 className="tertiary-title">
                    {/* SINAV KODU GİR */}
                    ENTER EXAM CODE
                </h3>
                <div className="content-block">
                    <div className="exam-wrp">
                        <p>
                            {/* Size verilen sınav kodu ile buradan sınava
                            girebilirsiniz. */}
                            You can enter the exam with the exam code given to you.
                        </p>
                        <form action="void()">
                            <div className="exam-code">
                                <div className="exam-code-left">
                                    {/* <label htmlFor="">exam code</label> */}
                                    <input
                                        ref="exam_code"
                                        type="text"
                                        // placeholder="Sınav Kodu"
                                        placeholder="Exam Code"
                                        // defaultValue="4A99-52E8"
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="box-btn"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.examStartHandler();
                                    }}
                                >
                                    {/* Git */}
                                    Start
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        setShowIntroductionData: data => dispatch(setShowIntroductionData(data)),
        spinner: data => dispatch(spinner(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExamStartByCode);
