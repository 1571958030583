const newsReducerDefaultState = {
    list: [],
    view: "list",
    detail: null
};

export default (state = newsReducerDefaultState, action) => {
    switch (action.type) {
        case "GET_RESULT_LIST":
            // console.log(action.payload);
            return {
                ...state,
                list: action.payload.data
            };

        case "GET_RESULT_DETAIL":
            return {
                ...state,
                // detail: action.payload.data,
                view: "detail"
            };

        case "CHANGE_TO_RESULT_VIEW_LIST":
            console.log('gere on reducer')
            return {
                ...state,
                view: "list"
            };

        default:
            return state;
    }
};
