export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const UNSET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const UNSET_ERROR_MESSAGE = "UNSET_ERROR_MESSAGE";
export const SET_LOGIN_PENDING = "SET_LOGIN_PENDING";
export const SET_LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const EXAM_START = "EXAM_START";
export const EXAM_SUBMIT = "EXAM_SUBMIT";
export const SAVE_ANSWERS = "SAVE_ANSWERS";
export const QUESTION_SELECT = "QUESTION_SELECT";
export const CHANGE_SHOW_QUESTION = "CHANGE_SHOW_QUESTION";
export const TIMER_START = "TIMER_START";
export const TIMER_STOP = "TIMER_STOP";
export const GET_NEWS_DATA = "GET_NEWS_DATA";
export const GET_GUIDE_DATA = "GET_GUIDE_DATA";
export const QUESTION_FAVORITE = "QUESTION_FAVORITE";
export const CHANGE_SEARCH_COURSE_TEXT = "CHANGE_SEARCH_COURSE_TEXT";
export const GET_RESULT_LIST = "GET_RESULT_LIST";
export const GET_RESULT_DETAIL = "GET_RESULT_DETAIL";
export const CHANGE_TO_RESULT_VIEW_LIST = "CHANGE_TO_RESULT_VIEW_LIST";
export const CHANGE_LEFT_BAR_FILTER = "CHANGE_LEFT_BAR_FILTER";
export const GET_SINGLE_POST = "GET_SINGLE_POST";
export const SHOW_EXAM_INTRODUCTION = "SHOW_EXAM_INTRODUCTION";
