import { getNews, getGuides, getPostBySlug } from "../services/api";
import { GET_NEWS_DATA, GET_GUIDE_DATA, GET_SINGLE_POST } from "./actions";
import { spinner } from "./spinner";

export const pullNewsData = () => {
    // console.log("pullNewsData calling.....");
    return dispatch => {
        dispatch(spinner(true));
        getNews()
            .then(function(data) {
                dispatch(getNewsData(data));
                dispatch(spinner(false));
            })
            .catch(function(err) {
                console.info(" news error", err);
            })
            .finally(() => {});
    };
};

export const pullGuidesData = () => {
    // console.log("pullGuidesData calling.....");
    return dispatch => {
        dispatch(spinner(true));
        getGuides()
            .then(function(data) {
                dispatch(getGuidesData(data));
                dispatch(spinner(false));
            })
            .catch(function(err) {
                console.info(" guides error", err);
            })
            .finally(() => {});
    };
};

export const getNewsData = data => {
    return {
        type: GET_NEWS_DATA,
        payload: data
    };
};
export const getGuidesData = data => {
    return {
        type: GET_GUIDE_DATA,
        payload: data
    };
};

export const getSinglePostData = data => {
    return {
        type: GET_SINGLE_POST,
        payload: data
    };
};

export const pullSinglePost = slug => {
    console.log("pullSinglePost calling.....", slug);
    return dispatch => {
        dispatch(spinner(true));
        getPostBySlug(slug)
            .then(function(data) {
                dispatch(getSinglePostData(data));
                dispatch(spinner(false));
            })
            .catch(function(err) {
                console.info(" single post error", err);
            })
            .finally(() => {});
    };
};
