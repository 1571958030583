import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'react-notifications/lib/notifications.css';

import './styles/css/bootstrap.min.css'
import './styles/css/all.min.css'

import './styles/css/font.css'
import './styles/css/style.css'


 import './styles/css/slick.css'
 import './styles/css/Util.css'
 import './styles/css/main.css'
 import './styles/css/responsive.css'

 if (process.env.NODE_ENV !== 'development') {//Disable logs on the production
    console.log = () => {}
  }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
